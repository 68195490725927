import React, {Component} from 'react';
import Meta from "../Meta";
import ReactGA from "react-ga";

class TargetGroup extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div className="portfolio">
                <Meta title={"Target Group - Read web design story I Codics"}
                      desc={"When designing Target Group website, one of our focuses was brand identity implementation. Read the full story on design process here."}
                      img={"/img/projects-img/target.jpeg"}/>
                <div className="portfolio-target-page">
                    {/*<img className="banner-img" src="/img/projects-img/target1.png" alt="target"/>*/}
                    <div className="test">

                    </div>

                    <img className="bg-arrow" src="/img/projects-img/arrow-yellow.png" alt="arrow-yellow"/>
                    <div className="pluse-icon">
                        <span className="icon-img"/>
                    </div>
                    {/*<span className="sub-text">*/}
                    {/*    We asked ourselves– “What creates the <b>first impression</b> in the website?”– <b>Home page?</b> Maybe the <b>Loading?</b>*/}
                    {/*    The <b>transition</b> from Loading to the Home page.*/}
                    {/*</span>*/}
                    <span className="sub-text">
                       While creating Target Group website we thought a lot about forming first impressions. We asked ourselves–
                       “What creates the first impression on the website?”– Home page? Maybe loading? Or the transitions?
                   </span>

                    <img className="banner-img" src="/img/projects-img/target2.gif" alt="target"/>
                    <img className="bg-arrow" src="/img/projects-img/arrow-yellow.png" alt="arrow-yellow"/>
                    <img className="banner-img " src="/img/projects-img/target3.gif" alt="target"/>
                    <img className="banner-img" src="/img/projects-img/target4.png" alt="target"/>
                    <img className="bg-arrow" src="/img/projects-img/arrow-yellow.png" alt="arrow-yellow"/>

                    {/*<h1 className="sub-title">Typeface & Color</h1>*/}
                    {/*<span className="sub-text">*/}
                       {/*Every detail in the website is deeply thought and analyzed and the typeface is*/}
                       {/*not an exception.*/}
                   {/*</span>*/}
                    {/*<div className="used-details">*/}
                        {/*<div>*/}
                            {/*<span className="used-details-txt">Charter</span>*/}
                            {/*<img className="used-details-img" src="/img/projects-img/A.png" alt="A"/>*/}
                            {/*<span className="used-details-txt">Short serifs and low contrast let us feel the huge impact the*/}
                               {/*Group has on the local industry market.</span>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                            {/*<img className="used-details-img" src="/img/projects-img/target-color.png" alt="A"/>*/}
                            {/*<span className="used-details-txt">The main theme of the website is grey which helps to create*/}
                               {/*a mature and serious atmosphere.</span>*/}
                        {/*</div>*/}
                    {/*</div>*/}

                    {/*<div className="pluse-icon ">*/}
                        {/*<span className="icon-img"/>*/}
                    {/*</div>*/}

                    <h1 className="sub-title">Flexible Grid</h1>
                    <span className="sub-text">
                        We experimented with the grid to create <b> a highly flexible asymmetric layout. </b> Grid became <b> the
                        core reference </b> for the UI solutions​​​​​​​.
                   </span>
                    <img className="banner-img large-img" src="/img/projects-img/target5.png" alt="target"/>
                    <img className="banner-img" src="/img/projects-img/target6.png" alt="target"/>
                    <img className="bg-arrow" src="/img/projects-img/arrow-yellow.png" alt="arrow-yellow"/>
                    <h1 className="sub-title">Responsive Design</h1>
                    <span className="sub-text">
                        <b> Responsive layout </b> dictates the organisation of the content and, thus, makes the whole website stylistically
                        <b> consistent and trendy.</b><br/>The responsiveness is a key point especially in B2B websites.
                        We worked hard to keep both the <b>UX & UI</b> of the website cool & user-friendly on all screens.
                   </span>
                    <img className="banner-img" src="/img/projects-img/target7.png" alt="target"/>



                    <div className="team grey">
                        <h2>CAST</h2>
                        <div className="team-members">
                            <div>
                                <b>Garik Gyulasaryan</b><br/>
                                Executive Producer
                            </div>
                            <div><b>Narine Ter-Ghevondyan</b><br/>
                                Production Manager
                            </div>
                            <div><b>Alexandr Hovhannisyan</b><br/>
                                UX/UI Designer
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default TargetGroup;
