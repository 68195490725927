import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Blogs from "./pages/Blogs";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import ProjectInner from "./pages/Projects";
import BlogInner from "./components/BlogInner";
import CareersForm from "./pages/CareersForm";
import NotFound from "./pages/NotFound";

// @withRouter
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toTop: false,
      toProject: false,
    };
  }

  componentDidMount() {
    if (this.props.location.pathname !== "/") {
      document.getElementById("myHeader").classList.add("show");
      document.body.classList.remove("isHome");
    } else {
      document.getElementById("myHeader").classList.remove("show", "showFast");
      document.body.classList.add("isHome");
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params !== prevProps.match.params) {
      if (this.props.location.pathname === "/") {
        document.body.classList.add("isHome");
      } else {
        document.body.classList.remove("isHome");
      }
    }
  }
  moveToTop = () => {
    this.setState({
      toTop: !this.state.toTop,
    });
  };

  moveToProjects = () => {
    this.setState({
      toProject: !this.state.toProject,
    });
  };

  render() {
    return (
      <main className="app">
        <Header
          moveToTop={this.moveToTop}
          moveToProjects={this.moveToProjects}
        />
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Home
                {...props}
                toTop={this.state.toTop}
                toProject={this.state.toProject}
              />
            )}
          />
          <Route exact path="/projects/:project" component={ProjectInner} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/blog" component={Blogs} />
          <Route exact path="/blog/*" component={BlogInner} />
          <Route exact path="/careers" component={Careers} />
          <Route
            exact
            path="/careers/aplication-form"
            component={CareersForm}
          />
          <Route component={NotFound} />
        </Switch>
      </main>
    );
  }
}

export default withRouter(App);
