import React, { Component } from "react";
// import {isDesktop} from "../../constants/Constants";
import Meta from "../Meta";
import ReactGA from "react-ga";
let isDesktop;
if (process.env.BROWSER) {
  isDesktop = window.matchMedia("(min-width: 812px)").matches;
}
class YerevanPark extends Component {
  state = {
    isMobile: false,
    isPlaying: false,
  };
  componentDidMount() {
    ReactGA.initialize("UA-106993262-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ isMobile: window.innerWidth <= 768 });
  }

  handlePlay = (e) => {
    this.setState({ isPlaying: true });
    const id = e.target.dataset.id || e.target.id;
    const player = document.getElementById(id);
    console.log(player);
    player.play();
  };

  handlePause = () => {
    this.setState({ isPlaying: false });
  };

  render() {
    const { isPlaying, isMobile } = this.state;
    const mobilePath = "/mobile";
    return (
      <div className="">
        <Meta
          title={"Yerevan Park - Explore website design story"}
          desc={""}
          img={"/img/projects-img/yerevanPark/yerevanPark_banner.jpg"}
        />
        <div className="yp-page">
          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark${
                isMobile ? mobilePath : ""
              }/yp_01.jpg`}
              alt=""
            />
          </div>
          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark/yp_02.jpg`}
              alt=""
            />
          </div>
          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark/yp_03.jpg`}
              alt=""
            />
          </div>
          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark/yp_04.jpg`}
              alt=""
            />
          </div>

          <div
            className="row justify-content-center"
            style={{ backgroundColor: "#282461" }}
          >
            <div className="col-md-5 px-0 img-container">
              <img
                className="w-100"
                src={`/img/projects-img/yerevanPark/yp_05_01.jpg`}
                alt=""
              />
            </div>
            <div className="col-md-5 px-0 img-container">
              <img
                className="w-100"
                src={`/img/projects-img/yerevanPark/yp_05_02.jpg`}
                alt=""
              />
            </div>
          </div>

          <div className="yp-player-container">
            <video
              id="yp-player_1"
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              className=""
              poster="/img/projects-img/yerevanPark/video_thumbnail/01.jpg"
              width="100%"
            >
              <source src="/movie/yerevan_park/yp_01.mp4" type="video/mp4" />
            </video>
            {!isPlaying && (
              <span
                data-id="yp-player_1"
                className="play-btn"
                onClick={this.handlePlay}
              />
            )}
          </div>

          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark${
                isMobile ? mobilePath : ""
              }/yp_06.jpg`}
              alt=""
            />
          </div>
          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark/yp_07.jpg`}
              alt=""
            />
          </div>

          <div
            className="row justify-content-center"
            style={{ backgroundColor: "#282461" }}
          >
            <div className="col-md-3 px-0 img-container">
              <img
                className="w-100"
                src={`/img/projects-img/yerevanPark/yp_08_01.jpg`}
                alt=""
              />
            </div>
            <div className="col-md-3 px-0 img-container">
              <img
                className="w-100"
                src={`/img/projects-img/yerevanPark/yp_08_02.jpg`}
                alt=""
              />
            </div>
            <div className="col-md-3 px-0 img-container">
              <img
                className="w-100"
                src={`/img/projects-img/yerevanPark/yp_08_03.jpg`}
                alt=""
              />
            </div>
          </div>

          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark${
                isMobile ? mobilePath : ""
              }/yp_09.jpg`}
              alt=""
            />
          </div>
          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark/yp_10.jpg`}
              alt=""
            />
          </div>
          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark/yp_11.jpg`}
              alt=""
            />
          </div>
          <div className="img-container">
            <img
              className="w-100"
              src={`/img/projects-img/yerevanPark/yp_12.jpg`}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default YerevanPark;
