import React, { Component } from "react";
import MyMenu from "./MyMenu";
import { Link, withRouter } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      scrollbar: false,
      toProject: false,
      // isBlog: false,
    };
  }

  toggleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  codicsClick = (e) => {
    if (this.props.location.pathname === "/") {
      e.preventDefault();
      this.props.moveToTop();
    }

    setTimeout(() => {
      this.props.location.hash = "";
      window.location.hash = "";
    }, 2000);

    this.setState({ isOpen: false });
  };

  projectsClick = (e) => {
    if (this.props.location.pathname === "/") {
      e.preventDefault();
      this.props.moveToProjects();
    }
    this.toggleMenu();
  };

  componentDidMount() {
    this.checkScrollbar();
    // if (this.props.location.pathname?.split("/")[2]?.includes("blog")) {
    //   this.setState({
    //     isBlog: true,
    //   });
    // } else {
    //   this.setState({
    //     isBlog: false,
    //   });
    // }
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.location.pathname !== this.props.location.pathname) {
  //     if (this.props.location.pathname?.split("/")[2]?.includes("blog")) {
  //       this.setState({
  //         isBlog: true,
  //       });
  //     } else {
  //       this.setState({
  //         isBlog: false,
  //       });
  //     }
  //   }
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //     this.checkScrollbar();
  // }

  checkScrollbar = () => {
    this.setState({ scrollbar: false });

    if (this.props.location.pathname === "/") {
    } else {
      this.setState({ scrollbar: true });
    }
  };

  render() {
    const { isOpen, scrollbar } = this.state;

    // this.checkScrollbar();
    return (
      <>
        <MyMenu
          {...this.props}
          isOpen={isOpen}
          onClick={this.toggleMenu}
          projectsClick={this.projectsClick}
        />
        <header className="app-header" id="myHeader">
          <div className="app-header-block">
            {/*+ `${scrollbar ? " headerLogoWithScrollbar" : ""}`*/}
            <div className={"app-header-logo"}>
              <Link to={"/"} onClick={(e) => this.codicsClick(e)}>
                {/*<img className="app-header-logo-big" src="/img/codics-icons/codics-logo.svg" alt=""/>*/}
                {/*<img className="app-header-logo-small" src="/img/codics-icons/codics-logo(old).png" alt=""/>*/}
                <img
                  className="app-header-logo-logo"
                  src="/img/codics-icons/Logo_element.svg"
                  alt=""
                />
              </Link>
              <Link to={"/"} onClick={(e) => this.codicsClick(e)}>
                <img
                  className="app-header-logo-text"
                  src="/img/codics-icons/Logo_text.svg"
                  alt=""
                />
              </Link>
            </div>
            <div
              className="app-header-burger"
              onClick={(e) => this.toggleMenu(e)}
            >
              <img
                className={isOpen ? "brgr brgr-1 toBottomRight" : "brgr brgr-1"}
                src="/img/codics-icons/menu-burger.svg"
                alt=""
              />
              <img
                className={isOpen ? "brgr brgr-2 toTopLeft" : "brgr brgr-2"}
                src="/img/codics-icons/menu-burger.svg"
                alt=""
              />
            </div>

            {/* <div className={`socials ${isBlog && "hide"}`} id="socials">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/CodicsProducers/"
                    target="_blank"
                  >
                    <img src="/img/codics-icons/fb@1x.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.behance.net/codics_production?tracking_source=search-all%7Ccodics"
                    target="_blank"
                  >
                    <img src="/img/codics-icons/behnce@1x.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/codicsproduction/"
                    target="_blank"
                  >
                    <img src="/img/codics-icons/insta@1x.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/codics/"
                    target="_blank"
                  >
                    <img src="/img/codics-icons/linkedin@1x.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </header>
      </>
    );
  }
}

export default withRouter(Header);
