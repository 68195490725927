import React, { Component } from "react";
import Meta from "../Meta";
import ReactGA from "react-ga";

class Reebok extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-106993262-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="portfolio">
        <Meta
          title={"Reebok Sports Club - Web development & design I Codics"}
          desc={
            "When creating Reebok Sports Club website we focused on visually transfering core belief system of the brand. Here is the story behind the design."
          }
          img={"/img/projects-img/reebok.png"}
        />

        <div className="portfolio-reebok">
          <div className="container">
            <img
              className="banner-img"
              src="/img/projects-img/reebok1.png"
              alt="reebok"
            />
            <img
              className="banner-img notebook absolute-center "
              src="/img/projects-img/reebok-notebook.png"
              alt="reebok"
            />
          </div>

          <div className="container">
            <div className="background-dark">
              <h1 className="sub-title title-center">THE CHALLENGE</h1>
              <span className="sub-text ">
                When creating Reebok website our main challenges were:
                <ul>
                  <li>1. Visually transfer the brand ideology.</li>
                  <li>
                    2. Present the physical complex, services & advantages in a
                    comprehensive way to facilitate the decision-making process.
                  </li>
                </ul>
              </span>
            </div>

            <img
              className="banner-img absolute-center absolute-center-2"
              src="/img/projects-img/reebok2.png"
              alt="reebok"
            />

            <div className="background background-red">
              <h1 className="sub-title title-center">INTERIM WEBSITE</h1>
              <span className="sub-text">
                2 interim websites were released before the final release. The
                releases were scheduled in accordance with Reebok Sports Club
                marketing campaign phases. First interim website informed that
                Reebok Sports Club Armenia is coming soon. Second version
                presented information on services & packages for potential
                visitors. Finally, the actual website with complete package of
                features was released.
              </span>
            </div>
          </div>

          <img
            className="banner-img"
            src="/img/projects-img/reebok3.jpg"
            alt="reebok"
          />

          <div className="container">
            <div className=" background background-green-light">
              <h1 className="sub-title title-center">
                USER FRIENDLY NAVIGATION
              </h1>
              <span className="sub-text">
                Another focus was to ensure ease of navigation and present
                information in a user friendly way.
              </span>
            </div>

            <img
              className="banner-img absolute-center"
              src="/img/projects-img/reebok4.png"
              alt="reebok"
            />

            <div className="background-black">
              <h1 className="sub-title title-center">MOBILE RESPONSIVENESS</h1>
              <span className="sub-text">
                The website is fully responsive. All the pages are designed and
                developed based on best practices, to provide flawless & smooth
                experience.
              </span>
            </div>
          </div>
          <div className="background-black noPadding">
            <img
              className="banner-img mini-img"
              src="/img/projects-img/reebok5.png"
              alt="reebok"
            />

            <div className="team white">
              <h2>CAST</h2>
              <div className="team-members">
                <div>
                  <b>Garik Gyulasaryan</b>
                  <br />
                  Executive Producer
                </div>
                <div>
                  <b>Narine Ter-Ghevondyan</b>
                  <br />
                  Production Manager
                </div>
                <div>
                  <b>Anush Mkhoyan</b>
                  <br />
                  Software Production Manager
                </div>
                <div>
                  <b>Sona Ghazaryan</b>
                  <br />
                  Software Production Manager
                </div>
                <div>
                  <b>Aram Martirosyan</b>
                  <br />
                  Software Producer
                </div>
                <div>
                  <b>Armen Papikyan</b>
                  <br />
                  Software Producer
                </div>
                <div>
                  <b>Gegham Khachatryan</b>
                  <br />
                  Software Producer
                </div>
                <div>
                  <b>Konstantin Sargsyan</b>
                  <br />
                  Software Producer
                </div>
                <div>
                  <b>Karen Vardazaryan</b>
                  <br />
                  Production Designer
                </div>
                <div>
                  <b>Siranush Hovsepyan</b>
                  <br />
                  Production Designer
                </div>
                <div>
                  <b>David Hunanyan</b>
                  <br />
                  Production Designer
                </div>
              </div>

              <div className="goTo white white">
                <a href="http://reeboksportsclub.com/am" target="_blank">
                  <span>Go To Website</span>
                  <img
                    className="arrow"
                    src="/img/projects-img/arrow.png"
                    alt="arrow"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Reebok;
