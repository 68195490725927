import React from "react";

const ProjectorLight = () => {
  return (
    <div>
      <img className="projector-light" src="/img/careers-img/newheroes.png" />
    </div>
  );
};

export default ProjectorLight;
