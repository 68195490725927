import React, {Component} from 'react'
import Meta from "../Meta";
import ReactGA from "react-ga";

class ImArmenia extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div className="portfolio">
                <Meta title={"ImArmenia - Learn about travel mobile app I Codics"}
                      desc={"ImArmenia is a mobile app that presents educational content in an entertaining way. Explore ImArmenia mobile app here."}
                      img={"/img/projects-img/im-armenia.png"}/>
                <div className="portfolio-ImArmenia-page">

                    <div className="part-1">
                        <div className="sub-part left">
                            <span className="sub-text white">
                                 <h3 className="sub-title white">COLOURFUL GRAPHICS</h3>
                                With the help of colourful graphics and technical solutions ImArmenia combined fun with educational content in a realistic and engaging way.
                            </span>
                        </div>
                        <img className="banner-img" src="/img/projects-img/ImArmenia1-1.png" alt="ImArmenia"/>

                        <div className="sub-part right">
                            <span className="sub-text white">
                            <h3 className="sub-title white">MULTILINGUAL EXPERIENCE</h3>
                                The application offers multilingual experience as one has the possibility to choose from English, French, Portuguese languages.
                            </span>
                        </div>

                        <img className="banner-img" src="/img/projects-img/ImArmenia1-2.jpeg" alt="ImArmenia"/>
                    </div>

                    <img className="banner-img" src="/img/projects-img/ImArmenia2.jpeg" alt="ImArmenia"/>

                    <div className="part-2">
                        <div className="sub-part">
                            <img className="sub-img" src="/img/projects-img/ImArmenia3-1.png" alt="ImArmenia"/>
                        </div>
                        <div className="sub-part right">
                            <img className="sub-img" src="/img/projects-img/ImArmenia3-2.png" alt="ImArmenia"/>
                            <span className="sub-text white">
                            <h3 className="sub-title white">SIGHTSEEING</h3>
                                With the help of the illustrated map, carefully curated educational content is presented in the form of small bites of interesting facts about popular tourist destinations.
                            </span>
                        </div>
                        <div style={{width: '100%', height: '170px'}}>
                            <div className='banner-backImg'
                                 style={{backgroundImage: "url('/img/projects-img/ImArmenia3-3.png')"}}/>
                        </div>
                    </div>

                    <div className="part-3 part3-desktop">
                        <div className="sub-part left">
                            <img className="sub-img" src="/img/projects-img/ImArmenia4-1.png" alt="ImArmenia"/>
                            <div className="svg-line">

                            </div>
                            <span className="sub-text yellow-line-text">
                            <h3 className="sub-title">VIDEO GENERATION</h3>
                             All the photos taken during the trip turn into a great memory reel after clicking on “Finish my trip” button.
                            </span>
                        </div>
                        <div className="sub-part left">
                            <div className="sub-text">
                                <h3 className="sub-title">YOUR PROFILE</h3>
                                <ul>
                                    <li>Learn Eastern Armenian as you pack your suitcase</li>
                                    <li>View your photo album and journal entries as you go</li>
                                    <li>Multiple user support</li>
                                    <li>Tap on the ˜ag to select your language.</li>
                                    <li>Explore Armenia™s beautiful historical sites</li>
                                    <li>Write your thoughts in a journal at each location</li>
                                </ul>
                            </div>
                            <img className="sub-img with-text" src="/img/projects-img/ImArmenia4-2.png"
                                 alt="ImArmenia"/>
                        </div>
                    </div>

                    <div className="part3-mobile">
                        <div className="part3TopSection">
                            <div>
                                <h3 className="part3SubTitle">YOUR PROFILE</h3>
                                <ul>
                                    <li>Learn Eastern Armenian as you pack your suitcase</li>
                                    <li>View your photo album and journal entries as you go</li>
                                    <li>Multiple user support</li>
                                    <li>Tap on the ˜ag to select your language.</li>
                                    <li>Explore Armenia™s beautiful historical sites</li>
                                    <li>Write your thoughts in a journal at each location</li>
                                </ul>
                            </div>
                            <img className="" src="/img/projects-img/ImArmenia4-1.png" alt="ImArmenia"/>
                        </div>
                        <div className="part3BottomSection">
                            <div>
                                <div className="svg-line"/>
                                <span>
                                    <h3 className="part3SubTitle">VIDEO GENERATION</h3>
                                     All the photos taken during the trip turn into a great memory reel after clicking on “Finish my trip” button.
                                </span>
                                <img className="part3SubImg" src="/img/projects-img/ImArmenia4-2.png"
                                     alt="ImArmenia"/>
                            </div>
                        </div>
                    </div>

                    <div className="team">
                        <h2>CAST</h2>
                        <div className="team-members">
                            <div><b>Ani Karapetyan</b><br/>
                                Spaceman
                            </div>
                            <div><b>David Hunanyan</b><br/>
                                UX / UI Designer
                            </div>
                            <div><b>Agata</b><br/>
                                Illustration Artist
                            </div>
                            <div><b>Lily Ghukasyan</b><br/>
                                iOS Developer
                            </div>
                            <div><b>Sevak Soghoyan</b><br/>
                                iOS Developer
                            </div>
                            <div><b>Suren Sardaryan</b><br/>
                                Android Developer
                            </div>
                        </div>

                        <div className="share-icons black">
                            <a href="https://apps.apple.com/us/app/im-armenia/id999237346" target="_blank"
                               rel="noopener noreferrer">
                                <img src="/img/projects-img/appStore.png" alt="appStore"/>
                            </a>

                            <a href="https://play.google.com/store/apps/details?id=com.agbu.imarmenia" target="_blank"
                               rel="noopener noreferrer">
                                <img src="/img/projects-img/googlePlay.png" alt="googlePlay"/>
                            </a>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

export default ImArmenia;
