import React from 'react';

import App from './App';
// import * as serviceWorker from './serviceWorker';

import ReactDOM from "react-dom";
import {Provider} from "mobx-react";
import {BrowserRouter as Router} from "react-router-dom";
import Favicon from 'react-favicon';

ReactDOM.hydrate(
    <Router>
        <Favicon url="/img/favicon.png"/>
        <Provider>
            <App/>
        </Provider>
    </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
// serviceWorker.unregister();