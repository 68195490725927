import React, { Component } from "react";

class ProjectFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirefox: false,
    };
  }

  componentDidMount() {
    navigator.userAgent.toLowerCase().indexOf("firefox") > -1 &&
      this.setState({ isFirefox: true });
  }

  render() {
    const { onClick, count, isCountShow } = this.props;
    return (
      <div className="project-footer" onClick={onClick}>
        <p>{this.props.title}</p>
        <svg
          className="footerSvg"
          width="45px"
          height="45px"
          viewBox="0 0 46 46"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <circle
            id="OvalFooter"
            stroke="#FFFFFF"
            cx="23"
            cy="23"
            r="20"
            className={isCountShow ? "hideShow" : ""}
          />
          {!isCountShow ? (
            <polygon
              id="Path"
              fill="#FFFFFF"
              points="20 15 21 15 21 25 20 25"
              transform="translate(2.5,4.5)"
            />
          ) : (
            <text
              x={`${this.state.isFirefox ? "25.5" : "23"}`}
              y={`${this.state.isFirefox ? "27" : "23"}`}
              fill="#fff"
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {count}
            </text>
          )}
        </svg>
      </div>
    );
  }
}

export default ProjectFooter;
