import React, {Component} from 'react'
import Meta from "../Meta";
import ReactGA from "react-ga";

class Eurnekian extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div className="portfolio">
                <Meta title={"Eurnekian School - See web & CMS development story I Codics"}
                      desc={"For Eurnekian School, our team conducted Rebranding, web design & development, CMS design & development. Discover project details here."}
                      img={"/img/projects-img/eurnekian.png"}/>
                <div className="portfolio-eurnekian-page">
                    <img className="banner-img" src="/img/projects-img/eurnekian1.png" alt="eurnekian"/>
                    <h1 className="sub-title title-center">THE PROJECT</h1>
                    <span className="sub-text">
                  Our team was approached to re-brand and develop a website for Eurnekian School. We closely collaborated with school students when working on the project, as they were the owners of the idea, the ones who were going to maintain and assure further maintenance of the website.
                   </span>
                    <div className="section">
                       <span className="content">
                           <span className="content-bg"/>
                           <h1 className="sub-title">Education</h1>
                           <span
                               className="text">is the most powerful weapon which you can use to change the world</span>
                           <img className="text-img" src="/img/projects-img/loader.png" alt="eurnekian"/>
                       </span>
                        <div className="second-image">

                        </div>
                        {/*<img className="section-img" src="/img/projects-img/eurnekian2.png" alt="eurnekian"/>*/}
                    </div>
                    {/*<h1 className="sub-title title-center">THE GOAL</h1>*/}
                    {/* <span className="sub-text">*/}

                    {/*         Our team was approached to re-brand and develop a website for Eurnekian School. We closely collaborated with school students when working on the project, as they were the owners of the idea, the ones who were going to maintain and assure further maintenance of the website.*/}
                    {/*</span>*/}
                    <img className="banner-img mini-img" src="/img/projects-img/eurnekian3.png" alt="eurnekian"/>
                    <img className="banner-img" src="/img/projects-img/line-bg.png" alt="eurnekian"/>

                    <h1 className="sub-title title-center">MAIN CHALLENGES</h1>
                    <span className="sub-text">

When developing the brand our main challenges were:
<ul>
                           <li>1. To create a brand identity that would emphasize all the values put in the advanced pedagogical methodology & the school vision.</li>
                           <li>2. In addition, we suggested brand implementation in the school environment. <br/>
                               For website development, our main aim was to position Eurnekian school as an international level public school, and create a platform, which would communicate the school advantages, success stories, daily life & important news.
                           </li>
                      </ul>
                    </span>

                    <div className='logo-container'>
                        <img className="banner-img logo-mini-img" src="/img/projects-img/eurnekian-school.png"
                             alt="eurnekian"/>
                        <img className="banner-img logo-mini-img" src="/img/projects-img/eurnekian-school2.png"
                             alt="eurnekian"/>
                        <img className="banner-img logo-mini-img" src="/img/projects-img/eurnekian-school3.png"
                             alt="eurnekian"/>
                    </div>

                    <div className="TEST">
                        <img className="banner-img" src="/img/projects-img/line-bg.png" alt="eurnekian"/>
                        {/*<img className="banner-img mini-img logo" src="/img/projects-img/eurnekian4.png" alt="eurnekian"/>*/}

                        <img className="banner-img mini-img TEST-sub1" src="/img/projects-img/eurnekian-icons.png"
                             alt="eurnekian"/>
                    </div>
                    <h1 className="sub-title title-center">ADMIN PANEL</h1>
                    <span className="sub-text">
                        Students of Eurnekian School were given custom admin panel for keeping website content up to date. It was developed according to Material Design Guidelines to ensure easy & intuitive user experience for any non-technical administrator.
                   </span>

                    <div className="TEST">
                        <img className="banner-img line-bg" src="/img/projects-img/eurnekian6.png" alt="eurnekian"/>
                        <img className="banner-img line-bg TEST-sub2" src="/img/projects-img/eurnekian8.png"
                             alt="eurnekian"/>

                    </div>
                    <h1 className="sub-title title-center">Mobile responsiveness</h1>
                    <span className="sub-text">The website is fully mobile responsive. All the pages are designed and developed to provide flawless & smooth experience with any device.</span>
                    <img className="banner-img mini-img" src="/img/projects-img/eurnekian7.png" alt="eurnekian"/>

                </div>
                <div className="team grey">
                    <h2>CAST</h2>
                    <div className="team-members">
                        <div><b>Garik Gyulasaryan</b><br/>
                            Executive Producer
                        </div>
                        <div><b>Narine Ter-Ghevondyan</b><br/>
                            Production Manager
                        </div>
                        <div><b>Armen Papikyan</b><br/>
                            Software Producer
                        </div>
                        <div><b>Aram Martirosyan</b><br/>
                            Software Producer
                        </div>
                        <div><b>Siranush Hovsepyan</b><br/>
                            Production Designer
                        </div>
                        <div><b>Alexandr Hovhannisyan</b><br/>
                            Production Designer
                        </div>
                        <div><b>Lilit Grigoryan</b><br/>
                            Software Production Manager
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Eurnekian;
