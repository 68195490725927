import React, { useEffect, useState, useRef } from "react";
import FileDropzone from "../components/FileDropzone";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import BtnLoader from "../components/BtnLoader";
import ThankYouModal from "../components/ThankYouModal";

const CareersForm = () => {
	const [loading, setLoading] = useState(false);
	const [resetFileCount, setResetFileCount] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalText, setModalText] = useState("");
	const [position, setPosition] = useState('')

	const submitBtn = useRef();
	const mainForm = useRef();
	const selectInp = useRef();
	const textInp = useRef();

	const toggleField = (hideObj, showObj) => {
		hideObj.disabled = true;
		hideObj.style.display = "none";
		showObj.disabled = false;
		showObj.style.display = "inline";
		showObj.focus();
	};

	const handleValidSubmit = (e, values) => {
		setLoading(true);
		submitBtn.current.disabled = true;
		let valuesToSend = {
			...values
		}
		if(values.job_title === 'other'){
			valuesToSend.job_title = values.other_job_title;
			delete valuesToSend.other_job_title
		}
		fetch("https://api.codics.am/api/jobRequests/send", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(valuesToSend),
		})
			.then((res) => res.json())
			.then((res) => {
				mainForm.current.reset();
				setLoading(false);
				setIsModalOpen(true);
				setModalText(
					"Thank you!<br/> Your application was submitted successfully"
				);
				setResetFileCount(true);
			})
			.catch((err) => {
				console.error(err);
				setIsModalOpen(true);
				setLoading(false);
				setModalText("Something went wrong, please try again later");
			});
	};
	const handleInvalidSubmit = (e) => {};

	useEffect(() => {
		document.getElementById("myHeader").classList.add("moveLeft", "showFast");
	});

	return (
		<div className="app-form">
			{isModalOpen && (
				<ThankYouModal
					text={modalText}
					onClose={() => {
						setIsModalOpen(false);
					}}
				/>
			)}
			<div className="careers-form-container">
				<h2 className="text-center">
					PLEASE FILL IN THE FORM <br /> TO QUEUE FOR THE CASTING
				</h2>
				<div className="careers-form-wrapper">
					<AvForm
						ref={mainForm}
						onValidSubmit={handleValidSubmit}
						onInvalidSubmit={handleInvalidSubmit}
						autoComplete="off"
					>
						<div className="row mx-0 w-100 mb-4">
							<AvField
								type="select"
								ref={selectInp}
								onChange={(e) => {
									setPosition(e.target.value)
						
								}}
								name="job_title"
								placeholder="Choose your Position*"
								validate={{
									required: {
										value: true,
										errorMessage: "Please choose your position",
									},
								}}
							>
								<option value="" disabled>Choose Your Position</option>
								<option value="Front-End Developer">Front-End Developer</option>
								<option value="Back-End Developer">Back-End Developer</option>
								<option value="ML Developer">ML Developer</option>
								<option value="Project Manager">Project Manager</option>
								<option value="other">Other</option>
							</AvField>

							{position === 'other' && <AvField
								name="other_job_title"
								placeholder="Enter the job title"
								autoComplete="off"
								validate={{
									required: {
										value: false,
										errorMessage: "Please enter the job title",
									},
									// pattern: {value: '^[A-Za-z]', errorMessage: 'Your username must be composed only with letter and numbers'},
								}}
								style={{ display: "none" }}
								onBlur={(e) => {
									const el = e.target;
									if (el.value === "") {
										toggleField(el, selectInp.getInput().current);
									}
								}}
							/>}

							{/* <input
								ref={textInp}
								name="job_title_other"
								style={{ display: "none" }}
								disabled={true}
								onChange={(e) => {
									setOtherValue(e.target.value);
								}}
								onBlur={(e) => {
									const el = e.target;
									if (el.value === "") {
										toggleField(el, selectInp.getInput().current);
									}
								}}
							/> */}
						</div>
						<div className="row">
							<div className="col-md-6">
								<AvField
									name="first_name"
									placeholder="Your Name*"
									autoComplete="given-name"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter your name",
										},
										// pattern: {value: '^[A-Za-z]', errorMessage: 'Your username must be composed only with letter and numbers'},
									}}
								/>

								<AvField
									name="email"
									placeholder="Email*"
									type="email"
									autoComplete="email"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter an Email",
										},
										email: {
											value: true,
											errorMessage: "This is not a valid email address.",
										},
									}}
								/>

								<AvInput
									type="textarea"
									name="message"
									placeholder="Motivation Letter"
									autoComplete="off"
								/>

								{/* {submitLoading ? (
                                <div className='form-loader'>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                </div>
                            ) : (
                                submitSuccess && (
                                    <div className="thank-you">
                                        <span>Thank you!</span>
                                        <div className="success-checkmark">
                                            <div className="check-icon">
                                                <span className="icon-line line-tip"/>
                                                <span className="icon-line line-long"/>
                                                <div className="icon-circle"/>
                                                <div className="icon-fix"/>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {!submitLoading && submitError &&
                            <span className='form-error'>Please try again later.</span>} */}
							</div>
							<div className="col-md-6">
								<AvField
									type="text"
									name="last_name"
									placeholder="Your Last Name*"
									autoComplete="family-name"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter your last name",
										},
									}}
								/>
								<AvField
									type="text"
									name="portfolio"
									placeholder="Portfolio link"
									autoComplete="off"
								/>
								<AvField
									type="text"
									name="linkedIn"
									placeholder="LinkedIn URL"
									autoComplete="off"
								/>
								<FileDropzone reset={resetFileCount} />
							</div>
							<button ref={submitBtn} className="mx-auto form-submit-btn">
								{loading ? <BtnLoader /> : "Submit"}
							</button>
						</div>
					</AvForm>
				</div>

				{/* //////////////////////////////////MOBILE////////////////////////////// */}

				<div className="careers-form-mobile">
					<AvForm
						onValidSubmit={handleValidSubmit}
						onInvalidSubmit={handleInvalidSubmit}
						autoComplete="off"
					>
					<AvField
						type="select"
						ref={selectInp}
						onChange={(e) => {
							setPosition(e.target.value)
				
						}}
						name="job_title"
						placeholder="Choose your Position*"
						validate={{
							required: {
								value: true,
								errorMessage: "Please choose your position",
							},
						}}
					>
						<option value="" disabled>Choose Your Position</option>
						<option value="Front-End Developer">Front-End Developer</option>
						<option value="Back-End Developer">Back-End Developer</option>
						<option value="ML Developer">ML Developer</option>
						<option value="Project Manager">Project Manager</option>
						<option value="other">Other</option>
					</AvField>

					{position === 'other' && <AvField
								name="other_job_title"
								placeholder="Enter the job title"
								autoComplete="off"
								validate={{
									required: {
										value: false,
										errorMessage: "Please enter the job title",
									},
									// pattern: {value: '^[A-Za-z]', errorMessage: 'Your username must be composed only with letter and numbers'},
								}}
								style={{ display: "none" }}
								onBlur={(e) => {
									const el = e.target;
									if (el.value === "") {
										toggleField(el, selectInp.getInput().current);
									}
								}}
							/>}

						<AvField
							name="first_name"
							placeholder="Your Name*"
							autoComplete="given-name"
							validate={{
								required: {
									value: true,
									errorMessage: "Please enter your name",
								},
							}}
						/>
						<AvField
							name="email"
							placeholder="Email*"
							type="email"
							validate={{
								required: {
									value: true,
									errorMessage: "Please enter an Email",
								},
								email: {
									value: true,
									errorMessage: "This is not a valid email address.",
								},
							}}
						/>

						<AvField
							type="text"
							name="last_name"
							placeholder="Your Last Name*"
							autoComplete={false}
							validate={{
								required: {
									value: true,
									errorMessage: "Please enter your last name",
								},
							}}
						/>
						<AvField
							type="text"
							name="portfolio"
							placeholder="Portfolio link"
							autoComplete={false}
						/>
						<AvField
							type="text"
							name="linkedin"
							placeholder="LinkedIn URL"
							autoComplete={false}
						/>

						<AvInput
							type="textarea"
							name="message"
							placeholder="Motivation Letter"
							autoComplete="new-password"
						/>
						<FileDropzone mobile reset={resetFileCount} />

						<button ref={submitBtn} className="mx-auto form-submit-btn">
							{loading ? <BtnLoader /> : "Submit"}
						</button>
					</AvForm>
				</div>
			</div>
		</div>
	);
};

export default CareersForm;
