import React, {Component} from 'react'
import Stars from "../components/Stars";
import Meta from "../components/Meta";
import ReactGA from 'react-ga';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {AvForm, AvField, AvInput} from 'availity-reactstrap-validation';

class Contact extends Component {

    state = {
        dropdownOpen: false,
        regardingError: false,
        regarding: '',
        submitSuccess: false,
        submitLoading: false,
        submitError: false,
    };

    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        if (this.props.location.pathname !== '/') {
            document.getElementById('myHeader').classList.add('show', 'moveLeft');
            [...document.querySelectorAll(".codics-scroll")].forEach(e => e.classList.add('visible'));
        } else {
            document.getElementById('myHeader').classList.remove('show', 'moveLeft', 'showFast');
        }
    }

    redirect = () => {
        this.props.history.push('/about');
    };

    toggle = (value) => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
            regardingError: value === '',
            regarding: value
        });
    };

    handleValidSubmit = (event, values) => {
        values = {
            regarding: this.state.regarding,
            ...values
        };

        console.log(values);
        if (this.state.regarding !== '') {
            this.setState({
                submitLoading: true,
                submitError: false,
                regardingError: false
            });

            // if (!true) {
            //     this.setState({
            //         submitLoading: false,
            //         submitSuccess: true,
            //         submitError: false
            //     });
            // } else {
            //     this.setState({
            //         submitLoading: false,
            //         submitSuccess: false,
            //         submitError: true
            //     })
            // }

            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(values)
            };
            fetch(`/api/contact`, requestOptions)
                .then(response => response.json())
                .then(data => {
                        // console.log(data);
                        if (data) {
                            this.setState({
                                submitLoading: false,
                                submitSuccess: true,
                                submitError: false
                            });
                        } else {
                            this.setState({
                                submitLoading: false,
                                submitSuccess: false,
                                submitError: true
                            })
                        }
                    }
                );

        } else {
            this.setState({
                regardingError: true
            })
        }
    }
    ;

    render() {
        const {regarding, regardingError, submitSuccess, submitError, submitLoading} = this.state;

        return (
            <div className="myPages contact-center">
                <Meta title={"Contact us - Software development headquarters I Codics"}
                      desc={"We love producing new software. Feel free to share with us your project idea and let’s work together."}
                      img={"/img/Codics.png"}/>
                <div className="my-title">
                    Contact
                </div>
                <div className="codics-stars show">
                    <Stars/>
                </div>
                <div className="my-container v-center">
                    <p className="bold-text text-center">
                        codics headquarters
                    </p>
                    <div className={`contact-wrapper ${submitSuccess && 'submitted'}`}>
                        <AvForm onValidSubmit={this.handleValidSubmit}
                            // autoComplete="off"
                        >
                            <AvField name="name" placeholder="Your Name"
                                // autoComplete='off'
                                     validate={{
                                         required: {value: true, errorMessage: "Please enter a Name"},
                                         // pattern: {value: '^[A-Za-z]', errorMessage: 'Your username must be composed only with letter and numbers'},
                                     }}
                            />
                            <AvField name="email" placeholder="Email" type="email"
                                // autoComplete="off"
                                     validate={{
                                         required: {value: true, errorMessage: "Please enter an Email"},
                                         email: {
                                             value: true,
                                             errorMessage: 'This is not a valid email address.'
                                         },
                                     }}
                            />
                            {/*<AvField type="select" name="select" placeholder="Regarding">*/}
                            {/*<option value="" disabled selected>Regarding</option>*/}
                            {/*<option value='Project Initiation'>Project Initiation</option>*/}
                            {/*<option value='Job opportunities'>Job opportunities</option>*/}
                            {/*<option value='Just saying hi!'>Just saying hi!</option>*/}
                            {/*</AvField>*/}
                            <Dropdown isOpen={this.state.dropdownOpen} toggle={() => {
                                this.setState({dropdownOpen: !this.state.dropdownOpen})
                            }}>
                                <DropdownToggle
                                    className={`${regardingError && 'error'} ${regarding === '' && 'initial'}`} caret>
                                    {regarding === '' ? 'Regarding' : regarding}
                                </DropdownToggle>
                                {regardingError && <div className="invalid-feedback">Please select</div>}
                                <DropdownMenu>
                                    <DropdownItem onClick={() => this.toggle('Project Initiation')}>Project
                                        Initiation</DropdownItem>
                                    <DropdownItem onClick={() => this.toggle('Job opportunities')}>Job
                                        opportunities</DropdownItem>
                                    <DropdownItem onClick={() => this.toggle('Just saying hi!')}>Just saying
                                        hi!</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <AvField type="textarea" name="message" placeholder="Message"
                                     autoComplete="new-password"
                                     validate={{
                                         required: {value: true, errorMessage: "Please enter a message"}
                                     }}/>
                            <button className='submit-button'>Submit</button>

                            {submitLoading ? (
                                <div className='form-loader'>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                </div>
                            ) : (
                                submitSuccess && (
                                    <div className="thank-you">
                                        <span>Thank you!</span>
                                        <div className="success-checkmark">
                                            <div className="check-icon">
                                                <span className="icon-line line-tip"/>
                                                <span className="icon-line line-long"/>
                                                <div className="icon-circle"/>
                                                <div className="icon-fix"/>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {!submitLoading && submitError &&
                            <span className='form-error'>Please try again later.</span>}
                        </AvForm>
                        <div className='contact-details'>
                            <ul className='contact-details-list'>
                                <li>
                                    <a href='tel:+374 93 00 48 71'>
                                        <span>PHONE  |</span>
                                        {/*<span> +374 60 69 96 69</span>*/}
                                        <span>+374 93 00 48 71</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='mailto:hello@codics.am'>
                                        <span>EMAIL  |</span>
                                        <span> hello@codics.am</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://goo.gl/maps/B937f1ZgkiTF1cP1A'>
                                        <span>ADDRESS  |</span>
                                        <span> 2/2 Melik-Adamyan, Yerevan, Armenia</span>
                                    </a>
                                </li>
                            </ul>
                            <div className="socials">
                                <ul>
                                    <li>
                                        <a href='https://www.facebook.com/CodicsProducers/' target="_blank">
                                            <img src="/img/codics-icons/fb@1x.svg" alt=""/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://www.behance.net/codics_production?tracking_source=search-all%7Ccodics'
                                           target="_blank">
                                            <img src="/img/codics-icons/behnce@1x.svg" alt=""/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://www.instagram.com/codicsproduction/' target="_blank">
                                            <img src="/img/codics-icons/insta@1x.svg" alt=""/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://www.linkedin.com/company/codics/' target="_blank">
                                            <img src="/img/codics-icons/linkedin@1x.svg" alt=""/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='project-footer' onClick={this.redirect}>
                    <p>
                        About
                    </p>
                </div>
            </div>
        )
    }
}

export default Contact;
