import React, {Component} from 'react'
import Meta from "../Meta";
import ReactGA from "react-ga";

class SelectArmenia extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
          <div className="portfolio">
            <Meta
              title={"Select Armenia - Trade portal development story I Codics"}
              desc={
                "Select Armenia is a unique platform that aims to attract direct investments into Armenian businesses. Read web development and design story."
              }
              img={"/img/projects-img/sa.jpeg"}
            />
            <div className="portfolio-sa-page">
              <div className="sub-img-wrapper">
                <img
                  className="sub-img"
                  src="/img/projects-img/sa1.png"
                  alt="select-arm"
                />
              </div>

              <img
                className="sub-img"
                src="/img/projects-img/sa2.png"
                alt="select-arm"
              />

              <h3 className="sub-title black">The Structure</h3>
              <ul className="sub-text black">
                <li>
                  With these goals in mind, the platform was divided into 2
                  sections:
                </li>
                <li>1. Investment</li>
                <li>2. Trade</li>
              </ul>

              <img
                className="sub-img"
                src="/img/projects-img/sa3.png"
                alt="select-arm"
              />
              <img
                className="sub-img"
                src="/img/projects-img/sa4.png"
                alt="select-arm"
              />
              <img
                className="sub-img"
                src="/img/projects-img/sa5.png"
                alt="select-arm"
              />

              <h3 className="sub-title black">Interactive Pages</h3>
              <span className="sub-text black">
                Through Benchmarking tool and Economy at a Glance infographic
                page users can easily acquire useful statistical data. For
                better user experience these pages were designed in an
                interactive way.  Industry best practices were thoroughly
                investigated for making interactive pages user friendly and
                visually appealing. 
              </span>

              <img
                className="sub-img"
                src="/img/projects-img/sa6.png"
                alt="select-arm"
              />

              <h3 className="sub-title black">Mobile Adaptation</h3>
              <span className="sub-text black">
                The website is fully responsive. All the pages are designed and
                developed based on industry best practices, to provide flawless
                & smooth experience
              </span>

              <img
                className="sub-img"
                src="/img/projects-img/sa7.png"
                alt="select-arm"
              />
              <img
                className="sub-img"
                src="/img/projects-img/sa8.png"
                alt="select-arm"
              />

              <div className="team white">
                <h2>STARRING</h2>
                <div className="team-members">
                  <div>
                    <b>Garik Gyulasaryan</b>
                    <br />
                    Executive Producer
                  </div>
                  <div>
                    <b>Narine Ter-Ghevondyan</b>
                    <br />
                    Production Manager
                  </div>
                  <div>
                    <b>Alexandr Hovhannisyan</b>
                    <br />
                    UX/UI Designer
                  </div>
                  <div>
                    <b>Armen Papikyan</b>
                    <br />
                    Frontend Developer
                  </div>
                  <div>
                    <b>Ani Mheryan</b>
                    <br />
                    Frontend Developer
                  </div>
                  <div>
                    <b>Robert Minasyan</b>
                    <br />
                    Frontend Developer
                  </div>

                  <div>
                    <b>Lilit Sukiasyan</b>
                    <br />
                    Spaceman
                  </div>
                  <div>
                    <b>Armen Sedrakyan</b>
                    <br />
                    UX/UI Designer
                  </div>
                  <div>
                    <b>Aram Martirosyan</b>
                    <br />
                    Backend Developer
                  </div>
                </div>

                <div className="goTo white">
                  <a
                    href="https://www.selectarmenia.com/en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Go To Website</span>
                    <img
                      className="arrow"
                      src="/img/projects-img/arrow.png"
                      alt="arrow"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default SelectArmenia;
