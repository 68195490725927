import React, {Component} from "react";
import Meta from "../Meta";
import ReactGA from "react-ga";

class Cnb extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    state = {
        isPlaying: false,
      };

    handlePlay = (e) => {
        this.setState({ isPlaying: true });
        const id = e.target.dataset.id || e.target.id;
        const player = document.getElementById(id);
        console.log(player);
        player.play();
      };
    
      handlePause = () => {
        this.setState({ isPlaying: false });
      };

    render() {
        const { isPlaying } = this.state;
        return (
            <div className="portfoilo">
                <Meta title={"CNB - Explore website design story"}
                      desc={""}
                      img={""}
                />

                <div className="portfoilo-cnb-page">

                    <div className="d-flex portfoilo-cnb-second-page" >
                        <video 
                        id="cnb-player_4" 
                        onPlay={this.handlePlay} 
                        onPause={this.handlePause} 
                        >
                            <source  src="/img/projects-img/cnb/2_1.mp4" type="video/mp4"></source>
                        </video>
                        {!isPlaying && (
                        <span
                            data-id="cnb-player_4"
                            className="play-btn"
                            onClick={this.handlePlay}
                        />
                        )}
                        <img  src="/img/projects-img/cnb/2_2.png" alt=""></img>
                    </div>

                    <div className="">
                        <img className="w-100 mb-0" src="/img/projects-img/cnb/3.png" alt=""></img>
                    </div>

                    <div className="portfolio-cnb-videos">
                        <video
                         id="cnb-player_1" 
                         className="w-100" 
                         onPlay={this.handlePlay} 
                         onPause={this.handlePause} 
                        >
                            <source src="/img/projects-img/cnb/4.mp4" type="video/mp4"></source>
                        </video>
                        {!isPlaying && (
                        <span
                            data-id="cnb-player_1"
                            className="play-btn"
                            onClick={this.handlePlay}
                        />
                        )}
                    </div>

                    <div className="">
                        <img className="w-100" src="/img/projects-img/cnb/5.png" alt=""></img>
                    </div>

                    <div className="">
                        <img className="w-100" src="/img/projects-img/cnb/6.png" alt=""></img>
                    </div>

                    <div className="">
                        <img className="w-100" src="/img/projects-img/cnb/7.png" alt=""></img>
                    </div>

                    <div className="">
                        <img className="w-100" src="/img/projects-img/cnb/8.png" alt=""></img>
                    </div>

                    <div className="">
                        <img className="w-100" src="/img/projects-img/cnb/9.png" alt=""></img>
                    </div>

                    <div className="portfolio-cnb-videos">
                        <video 
                        id="cnb-player_2" 
                        className="w-100" 
                        onPlay={this.handlePlay} 
                        onPause={this.handlePause} 
                        >
                            <source src="/img/projects-img/cnb/10.mp4" type="video/mp4"></source>
                        </video>
                        {!isPlaying && (
                        <span
                            data-id="cnb-player_2"
                            className="play-btn"
                            onClick={this.handlePlay}
                        />
                        )}
                    </div>

                    <div className="">
                        <img className="w-100" src="/img/projects-img/cnb/11.png" alt=""></img>
                    </div>

                    <div className="portfolio-cnb-videos">
                        <video 
                        id="cnb-player_3" 
                        className="w-100" 
                        onPlay={this.handlePlay} 
                        onPause={this.handlePause} 
                        >
                            <source src="/img/projects-img/cnb/12.mp4" type="video/mp4"></source>
                        </video>
                        {!isPlaying && (
                        <span
                            data-id="cnb-player_3"
                            className="play-btn"
                            onClick={this.handlePlay}
                        />
                        )}
                    </div>

                    <div className="">
                        <img className="w-100" src="/img/projects-img/cnb/13.png" alt=""></img>
                    </div>

                    <div className="">
                        <img className="w-100" src="/img/projects-img/cnb/14.png" alt=""></img>
                    </div>

                </div>
            </div>
        )
    }
}

export default Cnb;