import React, { Component, Fragment } from "react";
import ProjectBanner from "../components/Projects";
import ProjectFooter from "../components/ProjectFooter";
import projects from "../data/projects";
// import {observer, inject} from 'mobx-react';
// import Abgu from "../components/Pages/AGBU";
import Reebok from "../components/Pages/Reebok";
import { Route, Switch, withRouter } from "react-router-dom";
import Cityzen from "../components/Pages/Cityzen";
import Decodics from "../components/Pages/Decodics";
import ImArmenia from "../components/Pages/ImArmenia";
import Eurnekian from "../components/Pages/Eurnekian";
import Asso from "../components/Pages/Asso";
import TargetGroup from "../components/Pages/TargetGroup";
import WCIT from "../components/Pages/WCIT";
import Dasa from "../components/Pages/Dasa";
import SelectArmenia from "../components/Pages/SelectArmenia";
import ReactGA from "react-ga";
import M4ff from "../components/Pages/M4ff";
import NotFound from "./NotFound";
import MusicRoom from "../components/Pages/MusicRoom";
import YerevanPark from "../components/Pages/YerevanPark";
import Cnb from "../components/Pages/Cnb";

class ProjectInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: [],
      notFound: false,
      count: 3,
      delay: 1000,
      isShow: false,
    };
  }

  componentWillMount() {
    console.log(this.props.match.params);
    let projectName = projects.data.find(
      (x) => x.url === this.props.match.params.project.toLowerCase()
    );
    console.log(projectName);
    if (projectName) {
      this.setState({
        projectName: projectName,
      });
    } else {
      this.setState({
        notFound: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        projectName: projects.data.find(
          (x) => x.url === this.props.match.params.project.toLowerCase()
        ),
        isShow: false,
      });
    }
  }

  tick() {
    this.setState({ count: this.state.count - 1 });
  }

  componentDidMount() {
    ReactGA.initialize("UA-106993262-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    window.scrollTo(0, 0);

    this.setState({
      projectName: projects.data.find(
        (x) => x.url === this.props.match.params.project
      ),
    });
    if (this.props.location.pathname !== "/") {
      document.getElementById("myHeader").classList.add("show", "moveLeft");
    } else {
      document
        .getElementById("myHeader")
        .classList.remove("show", "moveLeft", "showFast");
    }
  }

  goToNextProject = (e, nextProject) => {
    clearInterval(this.timer);
    this.timer = setInterval(this.tick.bind(this), 1000);
    this.setState({
      isShow: true,
    });
    window.setTimeout(() => {
      clearInterval(this.timer);
      this.setState({
        isShow: false,
        count: 3,
      });
      this.props.history.push("/projects/" + nextProject);
    }, this.state.delay * 3); //
  };

  componentWillReceiveProps() {
    window.scrollTo(0, 0);
  }

  render() {
    const { projectName, count, isShow, notFound } = this.state;
    let indexOfNextProject = 0;
    let nextProject = projects.data[indexOfNextProject];
    const project = projects.data.find((project, index) => {
      indexOfNextProject = index + 1;
      return project.url === this.props.match.params.project.toLowerCase();
    });
    if (projects.data[indexOfNextProject]) {
      nextProject = projects.data[indexOfNextProject];
    }
    const pathname = this.props.match.params.project.toLowerCase();
    return notFound ? (
      <NotFound />
    ) : (
      <div
        className={
          `${isShow ? "app-project animated" : "app-project animated fadeIn"}
          ${pathname}`
        }
      >
        {project && (
          <Fragment>
            <ProjectBanner
              title={projectName.title}
              bgTitle={projectName.bgTitle}
              desc={projectName.desc}
              bgUrl={
                projectName.imgInner ? projectName.imgInner : projectName.img
              }
              bgUrlMobile={projectName.imgMobile}
              hideInnerTitle={projectName.hideInnerTitle || false}
            />
            <Switch>
              <Route exact path="/projects/reebok" component={Reebok} />
              <Route exact path="/projects/cityzen" component={Cityzen} />
              <Route exact path="/projects/decodics" component={Decodics} />
              <Route exact path="/projects/im-armenia" component={ImArmenia} />
              <Route
                exact
                path="/projects/eurnekian-school"
                component={Eurnekian}
              />
              {/* <Route
                exact
                path="/projects/target-group"
                component={TargetGroup}
              /> */}
              <Route exact path="/projects/wcit" component={WCIT} />
              <Route exact path="/projects/dasa" component={Dasa} />
              <Route
                exact
                path="/projects/select-armenia"
                component={SelectArmenia}
              />
              <Route exact path="/projects/asso" component={Asso} />
              <Route exact path="/projects/m4ff" component={M4ff} />
              <Route exact path="/projects/music-room" component={MusicRoom} />
              <Route
                exact
                path="/projects/yerevan-park"
                component={YerevanPark}
              />
              <Route exact path="/projects/cnb" component={Cnb} />
            </Switch>

            <ProjectFooter
              title={"SEE NEXT PROJECT"}
              onClick={(e) => this.goToNextProject(e, nextProject.url)}
              count={count}
              isCountShow={isShow}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

// const Projects = inject('appStore')(
//     observer(({history, match}) => {
//         return <ProjectInner  history={history} match={match} />;
//     })
// );

export default withRouter(ProjectInner);
