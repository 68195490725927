import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { AvField } from "availity-reactstrap-validation";

function FileDropzone({ mobile, reset }) {
  const [fileCount, setFileCount] = useState(0);
  const [files, setFiles] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setFileCount(0);
    setFiles(null);
    setDisabled(false);
  }, [reset]);

  const onDrop = useCallback((acceptedFiles) => {
    const fd = new FormData();
    acceptedFiles.forEach((file, idx) => {
      fd.append(`file_${idx}`, file);
    });
    setDisabled(true);
    fetch("https://api.codics.am/api/jobRequests/upload_files", {
      method: "POST",
      headers: {
        enctype: "multipart/form-data",
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((res) => {
        const files = Object.values(res);
        setDisabled(false);
        setFileCount(files.length);
        setFiles(files);
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".docx, .pdf",
  });

  return (
    <>
      <div {...getRootProps()} className="dropzone">
        <input
          name="resume"
          disabled={disabled}
          required
          {...getInputProps()}
        />
        <div className="dropzone-img-wrapper">
          <img src="/img/codics-icons/plus.png" alt="" />
        </div>
        <div className="pl-4 position-relative">
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>
              {mobile ? "CLICK TO ADD" : "DRAG AND DROP"}
              <br />
              YOUR CV HERE
            </p>
          )}
          <p className="file-count">
            <i>
              {disabled
                ? "Uploading File(s)..."
                : `${fileCount} file(s) selected`}
            </i>
          </p>
        </div>
      </div>
      <AvField
        type="hidden"
        name="resume"
        validate={{
          required: {
            value: true,
            errorMessage: disabled
              ? "Please wait for files upload"
              : "Please upload CV",
          },
        }}
        value={files}
      />
    </>
  );
}

export default FileDropzone;
