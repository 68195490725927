import React, {Component} from 'react'
import Meta from "../Meta";
import ReactGA from "react-ga";
// import {isDesktop} from "../../constants/Constants";

class Asso extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div className="portfolio">
                <Meta title={"ASSO - Read mobile app development & design story I Codics"}
                      desc={"ASSO mobile app users can enjoy classical music with just one tap. Learn more about the interactive features in our story."}
                      img={"/img/projects-img/asso.png"}/>
                <div className="portfolio-asso-page">

                    <div className="container con1">

                        <h3 className="sub-title">The Goal</h3>
                        <span className="sub-text">
                            The main purpose of the app is to attract, interest and engage users in classical music, inform about ASSO’s upcoming and past activities, increase concert attendances and ensure an easy platform for ticket ordering.
                        </span>
                        <img className="sub-img" src="/img/projects-img/asso1.png" alt="asso"/>

                    </div>

                    <div className="container con2" style={{ backgroundImage: 'url(/img/projects-img/asso2.png)'}}>

                        <h3 className="sub-title white">The Challenge</h3>
                        <span className="sub-text white">
                            Our team was approached to create an app that would:<br/>
                            1. Ensure highly engaging user experience with the help of intuitive and enhanced interface, smooth transitions and innovative features.<br/>
                            2. Present concerts and festivals in an attractive way.<br/>
                            3. Encourage concert attendances through targeted push notifications based on user preferences.
                        </span>

                        <img className="sub-img" src="/img/projects-img/asso3.png" alt="asso"/>

                    </div>

                    <img className="sub-img gradient" src="/img/projects-img/asso4.png" alt="asso"/>

                    <h3 className="sub-title">Interactive Play Button</h3>
                    <span className="sub-text">
                            The play button is an innovative tool that enables promoting excerpts from upcoming concerts without compromising main functionalities of the app. With just one swipe users can enjoy classical music.
                        </span>

                    <div className="container con3" style={{ backgroundImage: 'url(/img/projects-img/asso5.png)'}}>

                        <img className="sub-img" src="/img/projects-img/asso6.png" alt="asso"/>


                    </div>

                    <h3 className="sub-title">Media</h3>
                    <span className="sub-text">
                            Since the goal of ASSO app is to increase audience engagement, the app contains a vast video library of compositions from previous concerts/festivals. The users can enjoy classical compositions as well as listen to interviews with Sergey Smbatyan - ASSO Artistic Director and Principal Conductor.
                        </span>

                    <img className="sub-img desktop" src="/img/projects-img/asso7.jpeg" alt="asso"/>
                    <img className="sub-img mobile" src="/img/projects-img/asso7-mobile.png" alt="asso"/>


                    <div className="team white">
                        <h2>STARRING</h2>
                        <div className="team-members">
                            <div><b>Garik Gyulasaryan</b><br/>
                                Executive Producer
                            </div>
                            <div><b>Narine Ter-Ghevondyan</b><br/>
                                Production Manager
                            </div>
                            <div><b>Lilit Sukiasyan</b><br/>
                                Software Production Manager
                            </div>
                            <div><b>Seda Sargsyan</b><br/>
                                Software Production Manager
                            </div>
                            <div><b>Lucine Yeghunyan</b><br/>
                                Production Designer
                            </div>
                            <div><b>Sevak Soghoyan</b><br/>
                                Spaceman
                            </div>
                            <div><b>Suren Sardaryan</b><br/>
                                Software Producer
                            </div>
                            <div><b>Arthur Avagyan</b><br/>
                                Software Producer
                            </div>
                            <div><b>Samvel Petrosyan</b><br/>
                                Software Producer
                            </div>
                            <div><b>Artyom Miskaryan</b><br/>
                                Software Producer
                            </div>
                        </div>

                        <div className="share-icons">
                            <a href="https://apps.apple.com/ag/app/asso/id1482410516" target="_blank" rel="noopener noreferrer">
                                <img src="/img/projects-img/appStore.png" alt="appStore"/>
                            </a>

                            <a href="https://play.google.com/store/apps/details?id=com.codics.asso"  target="_blank" rel="noopener noreferrer">
                                <img src="/img/projects-img/googlePlay.png" alt="googlePlay"/>
                            </a>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

export default Asso;
