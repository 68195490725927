import React, { Component } from "react";
import MySlider from "../components/Swiper";
import HomeProjectInfo from "../components/HomeProjectInfo";
import Meta from "../components/Meta";
import Loader from "../components/Loader";
import ReactGA from "react-ga";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      delay: 10,
      removeLoader: false,
      toTop: false,
      toProject: false,
      numberOfSlides: 0,
      toTest: true,
      valueForbgTitle: 0,
      titleBoolean: false,
    };
  }

  setTitle = (argument) => {
    this.setState({
      titleBoolean: argument,
    });
  };

  getValue = (argument) => {
    this.setState({
      valueForbgTitle: argument,
    });
  };

  // getLastDigit = (string) =>{
  //     return string.slice(-1)
  // };
  // chooseSlide =() =>{
  //     if(this.props.location.hash){
  //         this.setState({
  //                 numberOfSlide: parseInt(this.props.location.hash.slice(-1))
  //         })
  //     }
  //     return this.state.number
  // };

  componentDidMount() {
    document
      .getElementById("myHeader")
      .classList.remove("show", "moveLeft", "showFast");

    ReactGA.initialize("UA-106993262-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.interval = setInterval(() => {
      this.setState(
        {
          count: this.state.count + 5,
        },
        () => {
          if (this.state.count >= 100) {
            clearInterval(this.interval);
            setTimeout(() => {
              !this.props.location.hash && this.toggleSymbols(1);
              document.getElementById("homeLoader").classList.remove("show");
              document.getElementById("myHeader").classList.add("show");
            }, 500);
          }
        }
      );
    }, this.state.delay);
  }

  toggleSymbols = (isEnable) => {
    let element = document.getElementById("symbolsContainer").classList;
    isEnable ? element.add("showSymbols") : element.remove("showSymbols");
  };

  componentDidUpdate(prevProps) {
    if (prevProps.toTop !== this.props.toTop) {
      this.setState({
        toTop: !this.state.toTop,
      });
    }

    if (this.props.location.hash && this.state.toProject === false) {
      // for returning to the last slide
      this.setState({
        toProject: !this.state.toProject,
      });
    }

    if (prevProps.toProject !== this.props.toProject) {
      this.setState({
        toProject: !this.state.toProject,
      });
    }
  }

  render() {
    return (
      <div className="app-home animated fadeIn">
        <Meta
          title={"Web & Mobile development - Software solutions I Codics"}
          desc={
            "Software Development is similar to movie production, that’s why we call ourselves Software Producers. Read the stories behind our tech and business solutions."
          }
          img={"/img/Codics.png"}
        />
        <Loader />
        <MySlider
          {...this.props}
          passValue={this.getValue}
          slideNumber={
            this.props.location.hash
              ? parseInt(this.props.location.hash.slice(-1))
              : this.state.numberOfSlides
          }
          toTop={this.state.toTop}
          toProject={this.state.toProject}
          startProjects={
            this.props.location.state && this.props.location.state.startProjects
          }
          count={this.state.count}
        />
      </div>
    );
  }
}

export default Home;
