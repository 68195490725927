import React, { Component } from "react";
import Swiper from "react-id-swiper";
// import {Navigation, Pagination, Scrollbar} from "swiper/js/swiper.esm";
import CodicsTop from "./CodicsTop";
import blogs from "../data/blogs/blogs-data";
import { Link } from "react-router-dom";
import Stars from "./Stars";
// import {isDesktop} from './../constants/Constants';
import HomeProjectInfo from "./HomeProjectInfo";

let isDesktop;
if (process.env.BROWSER) {
  isDesktop = window.matchMedia("(min-width: 812px)").matches;
}

class BlogSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStars: false,
      slide: 0,
      link: true,
      hash: false,
      acceptSlideTitle: false,
      currentSlideNumber: 0,
    };

    this.swiperNestedParams = {
      containerModifierClass: "mySwiper-",
      slideActiveClass: "mySwiper-slide-active",
      slideNextClass: "mySwiper-slide-next",
      slidePrevClass: "mySwiper-slide-prev",
      mousewheel: {
        invert: false,
        // eventsTarged: ".scrollSwiper-0",
      },
      // hashNavigation: true,
      replaceState: true,
      spaceBetween: 0,
      speed: 1800,
      getSwiper: this.swiperNestedInstance,
      direction: "vertical",
      allowTouchMove: !isDesktop,
      scrollbar: {
        el: ".swiper-scrollbar",
        draggable: isDesktop,
        dragSize: isDesktop ? 21 : 10,
      },
      nested: false,
      initialSlide: 0,
      // allowSlidePrev: this.props.startProjects,
      // allowSlideNext: this.props.startProjects,
      releaseOnEdges: true,
      autoHeight: true,
    };

    this.homeIndex = 1;
  }

  componentDidMount() {
    document.getElementById("myHeader").classList.add("moveLeft");
    // transform: translateY(-50%) scale(1, 1);
    this.setState({
      showStars: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {
      [...document.querySelectorAll(".myImg")].forEach((e) =>
        e.classList.add("showSlide")
      );
    }, 500);
    if (prevProps !== this.props || prevState !== this.state) {
    }
  }

  sortByDate(data) {
    let sortedBlogs = [
      ...data.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      ),
    ];
    return sortedBlogs;
  }

  breakDataToThreeElems = (data) => {
    const newArr = [];
    let dataArr = [...data];
    while (dataArr.length > 3) {
      let tempArr = dataArr.splice(0, 3);
      newArr.push(tempArr);
    }
    newArr.push(dataArr);
    return newArr;
  };

  swiperNestedInstance = (props) => {
    this.swiperNested = props;
    const { swiperNested } = this;

    if (swiperNested !== null) {
      swiperNested.el.classList.add("notTouched");
      // this.props.passValue(swiperNested.realIndex);

      this.setState({
        acceptSlideTitle: true,
        currentSlideNumber: swiperNested.realIndex,
      });

      swiperNested.on("scrollbarDragStart", () => {
        // swiper.removeSlide(0)
        swiperNested.wrapperEl.style.webkitTransform =
          "translate3d(0px," +
          swiperNested.translate * 0.3 +
          "px,0px) scale(0.3)";
        swiperNested.wrapperEl.style.transform =
          "translate3d(0px," +
          swiperNested.translate * 0.3 +
          "px,0px) scale(0.3)";
        swiperNested.wrapperEl.style.transitionDuration = "500ms";
        swiperNested.wrapperEl.style.webkitTransitionDuration = "500ms";
        swiperNested.el.classList.remove("notTouched");
        swiperNested.el.classList.add("isTouched");
      });
      swiperNested.on("scrollbarDragMove", () => {
        swiperNested.wrapperEl.style.webkitTransform =
          "translate3d(0px," +
          swiperNested.translate * 0.3 +
          "px,0px) scale(0.3)";
        swiperNested.wrapperEl.style.transform =
          "translate3d(0px," +
          swiperNested.translate * 0.3 +
          "px,0px) scale(0.3)";
        swiperNested.wrapperEl.style.transitionDuration = "500ms";
        swiperNested.wrapperEl.style.webkitTransitionDuration = "500ms";
        swiperNested.el.classList.remove("notTouched");
        swiperNested.el.classList.add("isTouched");
      });
      swiperNested.on("scrollbarDragEnd", () => {
        // swiperNested.prependSlide(`${CodicsTop}`)
        swiperNested.wrapperEl.style.webkitTransform =
          "translate3d(0px," + swiperNested.translate + "px,0px) scale(1)";
        swiperNested.wrapperEl.style.transform =
          "translate3d(0px," + swiperNested.translate + "px,0px) scale(1)";

        swiperNested.el.classList.add("notTouched");
        swiperNested.el.classList.remove("isTouched");
      });

      swiperNested.on("transitionStart", () => {
        this.swiperNestedParams.slideToClickedSlide = true;
      });

      setTimeout(() => {
        swiperNested.update();
      }, 3000);
    }
  };

  // setNestedSwiperStatus = (val) => {
  //   // console.log(`NestedSwiper = ${val}`);
  //   this.swiperNested.allowSlidePrev = val;
  //   this.swiperNested.allowSlideNext = val;
  //   // this.swiperMain.update();
  //   this.swiperNested.update();
  // };

  render() {
    const { showStars } = this.state;
    let sortedBlogs = this.sortByDate(blogs.data);
    const data = this.breakDataToThreeElems(sortedBlogs);
    const mobileData = sortedBlogs;
    return (
      <div id="blog-slider">
        <div className="my-title">Blogs</div>
        <div className="home-slider-wrapper" id="homeSliderWrapper">
          {/* <HomeProjectInfo /> */}
          <div className={showStars ? "codics-stars show" : "codics-stars"}>
            <Stars />
          </div>

          <div id="desktopSwiper">
            <Swiper {...this.swiperNestedParams}>
              {data.map((item, i) => (
                <div
                  className={`mySwiper-slide scrollSwiper-${i}`}
                  key={i}
                  // data-hash={item[0]?.slug}
                >
                  {/*Desktop version*/}
                  <div className="row mx-0 slides-desktop-container">
                    {item.map((el, idx) => {
                      return (
                        <div className="blog-item d-flex flex-column">
                          <div
                            className="myImg px-0"
                            style={{
                              backgroundImage: `url(${el?.img})`,
                            }}
                          >
                            <Link
                              to={this.state.link ? `/blog/${el?.slug}` : ""}
                              onClick={() => {}}
                            >
                              <h2
                              className="blog-name"
                              dangerouslySetInnerHTML={{
                                __html: el?.title,
                              }}
                              />
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </Swiper>
          </div>

          <div id="mobileSwiper">
            <Swiper {...this.swiperNestedParams}>
              {mobileData.map((el, i) => (
                <div
                  className={`mySwiper-slide scrollSwiper-${i}`}
                  key={i}
                  // data-hash={el?.slug}
                >
                  <div
                    className="myImg mobile-portrait"
                    style={{
                      backgroundImage: `url(${
                        el.imgMobile ? el.imgMobile : el.img
                      })`,
                    }}
                  >
                    <Link to={`/blog/${el?.slug}`} onClick={() => {}}>
                      <h2
                      className="blog-name"
                      dangerouslySetInnerHTML={{
                        __html: el?.title,
                      }}
                      />
                    </Link>
                  </div>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogSlider;
