import React, { Component } from "react";
import BlogSlider from "../components/BlogSlider";
import HomeProjectInfo from "../components/HomeProjectInfo";
import Meta from "../components/Meta";
import Loader from "../components/Loader";
import ReactGA from "react-ga";

class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      delay: 10,
      removeLoader: true,
      toTop: false,
      toProject: false,
      numberOfSlides: 0,
      toTest: true,
      valueForbgTitle: 0,
      titleBoolean: false,
    };
  }

  componentDidMount() {
    // document
    //   .getElementById("myHeader")
    //   .classList.remove("show", "moveLeft", "showFast");

    ReactGA.initialize("UA-106993262-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    this.interval = setInterval(() => {
      this.setState(
        {
          count: this.state.count + 5,
        },
        () => {
          if (this.state.count >= 100) {
            clearInterval(this.interval);
          }
        }
      );
    }, this.state.delay);
  }

  render() {
    return (
      <div className="app-home animated fadeIn">
        <BlogSlider />
      </div>
    );
  }
}

export default Blogs;
