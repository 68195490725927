import React, { Component } from "react";
import ReactGA from "react-ga";
import Swiper from "react-id-swiper";
// import Swiper from "swiper";
import jsonData from "../data/careers/careers.json";
import ProjectorLight from "../components/ProjectorLight";
import CareerModal from "../components/CareerModal";
import { Link } from "react-router-dom";

class Careers extends Component {
	componentDidMount() {
		document.getElementById("myHeader").classList.add("moveLeft", "showFast");
		ReactGA.initialize("UA-106993262-1");
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	state = {
		hideSwiper: false,
		modalIsOpen: false,
		modalData: null,
	};

	params = {
		spaceBetween: 1,
		slidesPerView: 1,
		centeredSlides: true,
		slideToClickedSlide: true,
		scrollbar: {
			el: ".swiper-scrollbar",
			draggable: true,
			snapOnRelease: false,
			dragSize: 40,
			hide: true,
		},
		mousewheel: {
			eventsTarget: ".swiper-wrapper",
		},
		grabCursor: true,
		on: {
			init: function () {
				this.el.classList.add("showSwiper");
			},
		},

		breakpoints: {
			480: {
				spaceBetween: 20,
				freeMode: true,
				freeModeMomentum: true,
				slidesPerView: 3.5,
				centeredSlides: true,
				slideToClickedSlide: true,

				pagination: {
					el: ".swiper-pagination",
					type: "bullets",
					clickable: true,
					bulletClass: "custom-bullet-class",
					bulletActiveClass: "active",
				},
			},
		},
	};

	openModal = (modalData) => {
		this.setState({
			modalIsOpen: true,
			hideSwiper: true,
			modalData,
		});
	};

	closeModal = () => {
		this.setState({
			modalIsOpen: false,
			hideSwiper: false,
		});
	};

	render() {
		const { data, benefits } = jsonData;
		const { modalIsOpen, modalData, hideSwiper } = this.state;
		return (
			<div className="careers myPages">
				<ProjectorLight />
				{!hideSwiper && (
					<>
						<Swiper {...this.params}>
							{data.map((job, index) => {
								console.log(job);
								return (
									<div
										key={`${job.title}-${index}`}
										className="swiper-slide"
										onClick={() =>
											this.openModal(
												data.find((el) => el.id === job.id).content
											)
										}
									>
										<h3>{job.title}</h3>
										<p>{job.short_desc}</p>
										<span className="about-role">ABOUT ROLE</span>
									</div>
								);
							})}

							<div className="swiper-slide">
								<Link
									to="/careers/aplication-form"
									className="career-form-link"
								>
									<h3>
										{data.length
											? "HAVEN’T FOUND A SUITABLE POSITION FOR YOU ?"
											: "CURRENTLY, WE DON’T HAVE OPEN VACANCIES"}
									</h3>
									<p>
										{data.length
											? "Submit your CV in your preferable position and we will reach you if you are shortlisted."
											: "However, you may submit your CV and we’ll consider you once we have a suitable position"}
									</p>
									<span className="about-role">SUBMIT CV</span>
								</Link>
							</div>
						</Swiper>
						{/* <div className="py-4">
              <div className="swiper-pagination"></div>
            </div> */}
					</>
				)}
				<div
					className={`careers-modal-container ${
						modalIsOpen ? "showModal" : ""
					}`}
				>
					{modalData && (
						<CareerModal
							isOpen={modalIsOpen}
							data={modalData}
							benefits={benefits}
							closeModal={this.closeModal}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default Careers;
