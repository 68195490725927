import React from "react";

export default function Loader() {
    return (
        <div className='app-home-loader show' id="homeLoader">
            <div>
                <span className={'loader-logo-wrapper black'}>
                    <img className={'loader-logo black'} src="/img/codics-icons/loader_codics_black.svg" alt="logo-black"/>
                </span>
                <span className={'loader-logo-wrapper white'}>
                    <img className={'loader-logo white'} src="/img/codics-icons/loader_codics_white.svg" alt="logo-white"/>
                </span>
            </div>
        </div>
    )
}