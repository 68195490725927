import React, { useState, useEffect, useRef } from "react";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import FileDropzone from "./FileDropzone";
import BtnLoader from "../components/BtnLoader";
import ThankYouModal from "./ThankYouModal";

const CareerModal = ({ data, benefits, closeModal, isOpen }) => {
	const [job, setJob] = useState(data);

	const [loading, setLoading] = useState(false);
	const [resetFileCount, setResetFileCount] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalText, setModalText] = useState("");
	const submitBtn = useRef();
	const mainForm = useRef();

	const handleValidSubmit = (e, values) => {
		setLoading(true);
		submitBtn.current.disabled = true;
		values["job_title"] = title;
		fetch("https://api.codics.am/api/jobRequests/send", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(values),
		})
			.then((res) => res.json())
			.then((res) => {
				console.log(res);
				mainForm.current.reset();
				setLoading(false);
				setIsModalOpen(true);
				setModalText("Your application was submitted successfully");
				setResetFileCount(true);
			})
			.catch((err) => {
				console.log(err);
				setIsModalOpen(true);
				setLoading(false);
				setModalText("Something went wrong, please try again later");
			});
	};
	const handleInvalidSubmit = () => {};

	useEffect(() => {
		setJob(data);
	}, [data]);

	const {
		title,
		deadline,
		desc,
		banner,
		responsibilities,
		qualifications,
		bonus,
		obj,
		obj2,
		about_tasks,
		about_tasks2,
		list,
		list2,
		have,
		havetwo,
		what,
		app,
		answer,
		skills,
		offer,
		perks,
		texttitle
	} = job;

	return (
		<>
			{isModalOpen && (
				<ThankYouModal
					text={modalText}
					onClose={() => {
						setIsModalOpen(false);
					}}
				/>
			)}
			<div className="careers-modal-backdrop" onClick={closeModal}>
				<div
					className="careers-modal"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<span className="close-btn" onClick={closeModal}>
						X
					</span>
					<div className="modal-container">
						<h2>{title}</h2>
						<p>{deadline}</p>
						{desc && (
							<p
								dangerouslySetInnerHTML={{
									__html: desc,
								}}
							/>
						)}
					</div>
					{banner && (
						<div
							className="modal-banner"
							style={{ backgroundImage: `url(${banner})` }}
						></div>
					)}
					<div className="modal-container">
						{obj && (
							<div>
								<h4>PRIMARY OBJECTIVES:</h4>
								{obj && (
									<p
										dangerouslySetInnerHTML={{
											__html: obj,
										}}
									/>
								)}
								{about_tasks && (
									<p
										dangerouslySetInnerHTML={{
											__html: about_tasks,
										}}
									/>
								)}
								<ul>
									{list.map((item, i) => {
										return <li key={`${item}-${i}`}>{item}</li>;
									})}
								</ul>
								{obj2 && (
									<p
										dangerouslySetInnerHTML={{
											__html: obj2,
										}}
									/>
								)}
								{about_tasks2 && (
									<p
										dangerouslySetInnerHTML={{
											__html: about_tasks2,
										}}
									/>
								)}
								<ul>
									{list2.map((item, i) => {
										return <li key={`${item}-${i}`}>{item}</li>;
									})}
								</ul>
							</div>
						)}
						{have && (
							<div>
								<h4>MUST-HAVES:</h4>
								{have.map((item, i) => {
									return <li key={`${item}-${i}`}>{item}</li>;
								})}
								<h4>NICE-TO HAVES:</h4>
								{havetwo.map((item, i) => {
									return <li key={`${item}-${i}`}>{item}</li>;
								})}
							</div>
						)}
						{what && (
							<div>
								<h4>WHAT YOU'LL GET?</h4>
								{what.map((item, i) => {
									return <li key={`${item}-${i}`}>{item}</li>;
								})}
							</div>
						)}
						{responsibilities && (
							<div>
								<h4>WHAT YOU'LL DO:</h4>
								{responsibilities.map((item, i) => {
									return <li key={`${item}-${i}`}>{item}</li>;
								})}
							</div>
						)}
						{qualifications && (
							<div>
								<h4>WHAT WE'RE SEEKING:</h4>
								<p>{texttitle}</p>
								{qualifications.map((item, i) => {
									return <li key={`${item}-${i}`}>{item}</li>;
								})}
							</div>
						)}
						{bonus && (
							<div>
								<h4>BONUS POINTS:</h4>
								{bonus.map((item, i) => {
									return <li key={`${item}-${i}`}>{item}</li>;
								})}
							</div>
						)}
						{skills && (
							<div>
								{skills.map((item, i) => {
									return <li key={`${item}-${i}`}>{item}</li>;
								})}
							</div>
						)}
						{offer && (
							<div>
								<h5>{about_tasks}</h5>
								{offer.map((item, i) => {
									return <li key={`${item}-${i}`}>{item}</li>;
								})}
							</div>
						)}
						{perks && (
							<div>
								<h4>PERKS & BENEFITS:</h4>
								{perks.map((item, i) => {
									return <li key={`${item}-${i}`}>{item}</li>;
								})}
							</div>
						)}
						{app && (
							<div className="app_form">
								<h4>APPLICATION PROCEDURE</h4>
								{app}
							</div>
						)}
						{answer && (
							<div>
								<h4>QUESTIONS TO ANSWER</h4>
								<ul>
									{answer.map((item, i) => {
										return <li key={`${item}-${i}`}>{item}</li>;
									})}
								</ul>
							</div>
						)}
					</div>
					<div className="careers-form-container">
						<div className="careers-form-title-container">
							PLEASE FILL IN THE FORM <br /> TO QUEUE FOR THE CASTING
						</div>
						<div className="careers-form-wrapper">
							<AvForm
								ref={mainForm}
								onValidSubmit={handleValidSubmit}
								onInvalidSubmit={handleInvalidSubmit}
								autoComplete="off"
							>
								<div className="row">
									<div className="col-md-6">
										<AvField
											name="first_name"
											placeholder="Your Name*"
											autoComplete={false}
											validate={{
												required: {
													value: true,
													errorMessage: "Please enter your name",
												},
												// pattern: {value: '^[A-Za-z]', errorMessage: 'Your username must be composed only with letter and numbers'},
											}}
										/>
										<AvField
											name="email"
											placeholder="Email*"
											type="email"
											// autoComplete="off"
											validate={{
												required: {
													value: true,
													errorMessage: "Please enter an Email",
												},
												email: {
													value: true,
													errorMessage: "This is not a valid email address.",
												},
											}}
										/>

										<AvInput
											type="textarea"
											name="message"
											placeholder="Motivation Letter"
											autoComplete="new-password"
										/>

										{/* {loading ? (
                                <div className='form-loader'>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                    <div className='circle'/>
                                </div>
                            ) : (
                                submitSuccess && (
                                    <div className="thank-you">
                                        <span>Thank you!</span>
                                        <div className="success-checkmark">
                                            <div className="check-icon">
                                                <span className="icon-line line-tip"/>
                                                <span className="icon-line line-long"/>
                                                <div className="icon-circle"/>
                                                <div className="icon-fix"/>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {!submitLoading && submitError &&
                            <span className='form-error'>Please try again later.</span>} */}
									</div>
									<div className="col-md-6">
										<AvField
											type="text"
											name="last_name"
											placeholder="Your Last Name*"
											autoComplete={false}
											validate={{
												required: {
													value: true,
													errorMessage: "Please enter your last name",
												},
											}}
										/>
										<AvField
											type="text"
											name="portfolio"
											placeholder="Portfolio link"
											autoComplete={false}
										/>
										<AvField
											type="text"
											name="linkedin"
											placeholder="LinkedIn URL"
											autoComplete={false}
										/>
										<FileDropzone reset={resetFileCount} />
									</div>
									<button ref={submitBtn} className="mx-auto form-submit-btn">
										{loading ? <BtnLoader /> : "Submit"}
									</button>
								</div>
							</AvForm>
						</div>

						{/* ////////////////////////////////////////// MOBILE ///////////////////////////////////////////////////// */}

						<div className="careers-form-mobile">
							<AvForm
								onValidSubmit={handleValidSubmit}
								onInvalidSubmit={handleInvalidSubmit}
								autoComplete="off"
							>
								<AvField
									type="hidden"
									name="job_title"
									required
									value={title}
								/>
								<AvField
									name="first_name"
									placeholder="Your Name*"
									autoComplete={false}
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter your name",
										},
									}}
								/>
								<AvField
									name="email"
									placeholder="Email*"
									type="email"
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter an Email",
										},
										email: {
											value: true,
											errorMessage: "This is not a valid email address.",
										},
									}}
								/>

								<AvField
									type="text"
									name="last_name"
									placeholder="Your Last Name*"
									autoComplete={false}
									validate={{
										required: {
											value: true,
											errorMessage: "Please enter your last name",
										},
									}}
								/>
								<AvField
									type="text"
									name="portfolio"
									placeholder="Portfolio link"
									autoComplete={false}
								/>
								<AvField
									type="text"
									name="linkedin"
									placeholder="LinkedIn URL"
									autoComplete={false}
								/>

								<AvInput
									type="textarea"
									name="message"
									placeholder="Motivation Letter"
									autoComplete="new-password"
								/>
								<FileDropzone mobile reset={resetFileCount} />

								<button ref={submitBtn} className="mx-auto form-submit-btn">
									{loading ? <BtnLoader /> : "Submit"}
								</button>
							</AvForm>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CareerModal;
