import React, {Component} from 'react';
import Symbols from "./Symbols";

class CodicsTop extends Component {


    render() {
        const RenderTopSymbols = () => {
            return (
                <div className='symbols-wrapper' id="symbolsWrapper">
                    <Symbols/>
                    <h1 className="home-slider-topTitle" id="HomeTopTitle">
                        We Produce
                        <br/>
                        Individual Stories
                        <br/>
                        With Creative
                        <br/>
                        Software
                    </h1>
                </div>
            )
        };
        return (
            <RenderTopSymbols {...this.props}/>
        )
    }
}

export default CodicsTop;
