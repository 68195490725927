import React, {Component} from 'react'
import Meta from "../Meta";
import ReactGA from "react-ga";

class Dasa extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div className="portfolio">
                <Meta title={"Das A - Explore mobile app development & design I Codics"}
                      desc={"Das A engages teens in classical music. Learn how Das A mobile app combines intellectual and social content."}
                      img={"/img/projects-img/dasa.png"}/>
                <div className="portfolio-dasa-page">


                    <div className="sub-img-wrapper">
                        <div className="first-image">
                        </div>
                        {/*<img className="sub-img" src="/img/projects-img/dasa1.gif" alt="dasa"/>*/}
                    </div>

                    <div className="img-container">
                        <h3 className="sub-title">The Challenge</h3>
                        <ul className="sub-text">
                            <li>Our challenge was to create an application that will allow teens:</li>
                            <li>1. To get free tickets to classical music concerts</li>
                            <li>2. To get access to educational content and</li>
                            <li>3. Share opinions, engage in discussions, earn badges</li>
                        </ul>
                    </div>

                    <div className="sub-img-wrapper">
                        <img className="sub-img" src="/img/projects-img/dasa2.png" alt="dasa"/>
                    </div>

                    <div className="sub-text-wrapper">
                        <h3 className="sub-title">The Impact</h3>
                        <span className="sub-text">
                              By providing small bites of educational content and serving as a one-click platform for ordering classical music concert tickets, Das A goal was to interest students in classical music and nudge recurring concert attendances with the help of targeted notifications.
                        </span>
                    </div>

                    <div className="sub-img-wrapper">
                        <img className="sub-img" src="/img/projects-img/dasa3.gif" alt="dasa"/>
                    </div>

                    <div className="sub-text-wrapper">
                        <span className="sub-text">
                              After the initial launch and gala concert, the aim was to have 20% recurring attendances during the next concert. The initial target was exceeded and there were 30% returned attendances during the 2nd concert.  
                        </span>
                    </div>

                    <div className="sub-img-wrapper">
                        <img className="sub-img" src="/img/projects-img/dasa4.png" alt="dasa"/>
                        <img className="design-1" src="/img/projects-img/dasa-design/dasa_design_1.png"
                             style={{width: '10%'}}
                             alt="effect"/>
                    </div>

                    <div className="sub-text-wrapper">
                        <h3 className="sub-title">Review Flow</h3>
                        <span className="sub-text">
                              Review flow is designed to actively engage students in opinion sharing and concert discussions. It also provides the opportunity to rate concerts.
                        </span>

                    </div>

                    <div className="sub-img-wrapper">
                        <img className="sub-img" src="/img/projects-img/dasa5.png" alt="dasa"/>
                        <img className="design-2" src="/img/projects-img/dasa-design/dasa_design_oval.png"
                             style={{width: '8%'}}
                             alt="effect"/>
                    </div>

                    <div className="sub-text-wrapper">
                        <h3 className="sub-title">My Profile Page</h3>
                        <span className="sub-text">
                              My profile page contains personal information of the user as well as completed quizzes and earned badges.
                        </span>
                    </div>

                    <div className="sub-img-wrapper">
                        <img className="sub-img" src="/img/projects-img/dasa6.png" alt="dasa"/>
                        <img className="design-2" src="/img/projects-img/dasa-design/dasa_design_2.png"
                             style={{width: '5%'}}
                             alt="effect"/>
                    </div>

                    <div className="sub-img-wrapper">
                        <img className="sub-img" src="/img/projects-img/dasa7.png" alt="dasa"/>
                        <img className="design-2" src="/img/projects-img/dasa-design/dasa_design_3.png"
                             style={{width: '15%', top: "-60px"}}
                             alt="effect"/>
                        <img className="design-3" src="/img/projects-img/dasa-design/dasa_design_4.png"
                             style={{width: '5%'}}
                             alt="effect"/>
                    </div>

                    <h3 className="sub-title">News Feed</h3>
                    <span className="sub-text">
                              News feed is a combination of social and intellectual aspects and contains small bites of interesting facts and photos from concerts. 
                        </span>


                    <div className="team">
                        <h2>STARRING</h2>

                        <div className="team-members">
                            <div>
                                <b>Garik Gyulasaryan</b><br/>
                                Executive Producer
                            </div>
                            <div><b>Narine Ter-Ghevondyan</b><br/>
                                Production Manager
                            </div>
                            <div><b>Lilit Sukiasyan</b><br/>
                                Spaceman
                            </div>
                            <div><b>Alexandr Hovhannisyan</b><br/>
                                UX/UI Designer
                            </div>

                            <div><b>Armen Sedrakyan</b><br/>
                                UX/UI Designer
                            </div>
                            <div><b>Sevak Soghoyan</b><br/>
                                iOS Developer
                            </div>
                            <div><b>Suren Sardaryan</b><br/>
                                Android Developer
                            </div>
                            <div><b>Aram Martirosyan</b><br/>
                                Backend Developer
                            </div>
                        </div>

                        <div className="share-icons black">
                            <a href="https://apps.apple.com/ag/app/das-a/id1441568804" target="_blank"
                               rel="noopener noreferrer">
                                <img src="/img/projects-img/appStore.png" alt="appStore"/>
                            </a>

                            <a href="https://play.google.com/store/apps/details?id=com.codics.dasa" target="_blank"
                               rel="noopener noreferrer">
                                <img src="/img/projects-img/googlePlay.png" alt="googlePlay"/>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Dasa;
