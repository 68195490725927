import React, { Component } from "react";
// import {isDesktop} from "../../constants/Constants";
import Meta from "../Meta";
import ReactGA from "react-ga";
let isDesktop;
if (process.env.BROWSER) {
  isDesktop = window.matchMedia("(min-width: 812px)").matches;
}
class M4ff extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-106993262-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  state = {
    isPlaying: false,
  };

  handlePlay = () => {
    this.setState({ isPlaying: true });
    const player = document.getElementById("video-player");
    player.play();
  };

  handlePause = () => {
    this.setState({ isPlaying: false });
  };

  render() {
    const { isPlaying } = this.state;
    return (
      <div className="">
        <Meta
          title={"Music for Future Foundation - Explore website design story"}
          desc={
            "Music for Future Foundation (M4FF) is a non profit organisation that aims to discover, develop, educate and support talanted musicians, art and culture representatives as well curtural  and educational institutions from the globe."
          }
          img={"/img/projects-img/m4ff/m4ff.png"}
        />
        <div className="m4ff-page">
          <div className="px-5 py-4">
            <img className="w-100" src="/img/projects-img/m4ff/m4ff_1.png" />
          </div>

          <div className="m4ff-text-container">
            <h3 className="m4ff-title">The Challenge</h3>
            <div className="m4ff-text">
              We were approached by M4FF to create:
              <ul>
                <li>
                  A branding that would be contemporary and modern yet classic,
                  elegant and solid.
                </li>
                <li>
                  A website that would communicate brand concept and philosophy
                  in a comprehensive way, present full information on
                  scholarship projects, past winners and their success stories,
                  as well as offer donation possibilities.
                </li>
                <li>
                  An easy-to-use admin panel for the News Section and Gallery.
                </li>
              </ul>
            </div>
          </div>

          <div className="m4ff-text-container branding">
            <h3 className="m4ff-title">Branding</h3>
            <div className="m4ff-text">
              The idea behind is to express growth, knowledge and continous
              development in music, The brand colors were selected as a classic
              combination that has a trendy and contemprorary fell
            </div>

            <div className="m4ff-img">
              <img src="/img/projects-img/m4ff/m4ff_2.png" alt="" srcset="" />
            </div>
          </div>

          <div className="m4ff-text-container mt-0 pt-5">
            <h3 className="m4ff-title">Animations</h3>
            <div className="m4ff-text">
              On hover animations, cursor animation and menu animation were
              designed to provide smooth transitions, draw attention to
              clickable sections, and provide enhanced user experience.
            </div>
          </div>

          <div className="m4ff-player-container">
            <video
              id="video-player"
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              className="px-5"
              width="100%"
            >
              <source src="/movie/m4ff-animation.mp4" type="video/mp4" />
            </video>
            {!isPlaying && (
              <span className="play-btn" onClick={this.handlePlay} />
            )}
          </div>

          <div className="m4ff-text-container mt-0 pt-5">
            <h3 className="m4ff-title">Scroll Menu</h3>
            <div className="m4ff-text">
              Our Projects page presents all the requirements, terms and
              conditions of the scholarship program and the details regarding
              the application process. So, the page was heavy in terms of
              content and required a solution that would make navigation more
              user-friendly and intuitive. Therefore, we implemented a Scroll
              Menu that allows users to jump to their preferred section and
              shows their current location in the Scroll Menu.
            </div>
          </div>

          <div className="px-0">
            <img className="w-100" src="/img/projects-img/m4ff/m4ff_3.png" />
          </div>

          <div className="px-5 py-5 w-100 mx-0 row">
            <div className="col-md-6 p-2">
              <img
                className="w-100"
                src="/img/projects-img/m4ff/m4ff_4-01.png"
              />
            </div>
            <div className="col-md-6 p-2">
              <img
                className="w-100"
                src="/img/projects-img/m4ff/m4ff_4-02.png"
              />
            </div>
            <div className="col-md-6 p-2">
              <img
                className="w-100"
                src="/img/projects-img/m4ff/m4ff_4-03.png"
              />
            </div>
            <div className="col-md-6 p-2">
              <img
                className="w-100"
                src="/img/projects-img/m4ff/m4ff_4-04.png"
              />
            </div>
          </div>

          <div className="m4ff-text-container mt-0 pt-5">
            <h3 className="m4ff-title">Admin Panel</h3>
            <div className="m4ff-text">
              Our team created a simple, easy-to-use and functional admin panel
              for the News Section and Gallery in accordance with Material
              Design guidelines. The admin panel features a simple and elegant
              interface and intuitive design to enable admins to easily create,
              edit and delete news, drag and drop images and input links into
              the gallery, fill in SEO-related data and draw attention to
              certain pieces of content with the help of Featured News option.
            </div>
          </div>

          <div className="m4ff-text-container mt-0 pt-5">
            <h3 className="m4ff-title">Responsive layout</h3>
            <div className="m4ff-text">
              The website is fully responsive. All the pages are designed and
              developed based on industry best practices, to provide flawless &
              smooth experience.
            </div>
          </div>

          <div className="m4ff-text-container mt-0 pt-5">
            <div className="img-overlap">
              <img
                src="/img/projects-img/m4ff/m4ff_5-01.png"
                className="img-overlap-first"
                alt=""
              />

              <img
                className="img-overlap-second"
                src="/img/projects-img/m4ff/m4ff_5-02.png"
                alt=""
              />
            </div>
          </div>
          <div className="bg-red">
            <div className="m4ff-text-container mt-0"></div>
          </div>

          <div className="thank-you-section">
            <div className="px-3 py-5 w-100 mx-0 row mobile-screenshots-container">
              <div className="py-3 col-md-3 col-6">
                <img
                  className="w-100"
                  src="/img/projects-img/m4ff/m4ff_6-01.png"
                />
              </div>
              <div className="py-3 col-md-3 col-6">
                <img
                  className="w-100"
                  src="/img/projects-img/m4ff/m4ff_6-02.png"
                />
              </div>
              <div className="py-3 col-md-3 col-6">
                <img
                  className="w-100"
                  src="/img/projects-img/m4ff/m4ff_6-03.png"
                />
              </div>
              <div className="py-3 col-md-3 col-6">
                <img
                  className="w-100"
                  src="/img/projects-img/m4ff/m4ff_6-04.png"
                />
              </div>
            </div>
            <div className="m4ff-text-container mt-0">
              <img
                className="w-100"
                src="/img/projects-img/m4ff/m4ff_thank_you.png"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default M4ff;
