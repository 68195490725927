import React, {Component} from 'react'
import Meta from "../Meta";
import ReactGA from "react-ga";

class Cityzen extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div className="portfolio">
                <Meta title={"CityZen - Explore web development & design story I Codics"}
                      desc={"A city of professions, CityZen combines safe educational and entertaining environments. Read the full story on CityZen website design and development."}
                      img={"/img/projects-img/cityzen.jpg"}/>
                <div className="portfolio-cityzen-page">
                    <img className="sub-img open-img" src="/img/projects-img/macbook-white.png" alt="cityzen"/>
                    <h3 className="sub-title">MAIN CHALLENGES</h3>
                    <span className="sub-text">
                        {/*<h2>MAIN CHALLENGES</h2>*/}
                        When our team was asked to create a website for Cityzen, our main challenges were:
                        <br/>1. Communicate brand concept & philosophy in a comprehensive way.
                        <br/>2. Earn parents’ trust as a safe & trustworthy entertainment.
                        <br/>3. Create intuitive website architecture.
                    </span>
                    <img className="sub-img" src="/img/projects-img/icons.png" alt="cityzen"/>
                    <div className="map-section">
                        <div className="map-section-block">
                            <h3 className="sub-title left">INTERACTIVE MAP</h3>
                            <span className="section-txt">
                            The interactive map presents the floorplan of Cityzen and presents brief information on each institution. Thus, before going to Cityzen, parents & kids can already have the full vision of what they will find once there.
                            </span>

                        </div>
                        <img className="sub-img map-img" src="/img/projects-img/map.png" alt="cityzen"/>
                        {/*<div className= "test">*/}
                        {/*</div>*/}
                    </div>
                    <div className="card-imgs">
                        <img src="/img/projects-img/card1.png" alt="cityzen"/>
                        <img src="/img/projects-img/card2.png" alt="cityzen"/>
                        <img src="/img/projects-img/card3.png" alt="cityzen"/>
                        <img src="/img/projects-img/card4.png" alt="cityzen"/>
                    </div>
                    <div className="team">
                        <h3 className="sub-title">PROFESSIONS</h3>
                        <span className="sub-text">
                            Professions page presents all the professions children can engage with during their visit. By clicking on any of them, visitors are directed to a full description of profession along with eligible age groups, main activities, duration and other details.
                        </span>
                        <img className="sub-img " src="/img/projects-img/cityzen-img1.png" alt="cityzen"/>

                        <div className="sub-text-wrapper">
                            <h3 className="sub-title">SPECIAL OCCASIONS</h3>
                            <span className="sub-text">
                            Besides being an edutainment (education + entertainment) center, Cityzen is a unique place to organize your child’s birthday party. Alongside other functions, our aim was to present thematic birthday organization as a separate service.
                        </span>
                        </div>

                        <img className="sub-img" src="/img/projects-img/cityzen-img2.jpeg" alt="cityzen"/>
                        <img className="sub-img " src="/img/projects-img/cityzen-img3.jpeg" alt="cityzen"/>
                        <img className="sub-img " src="/img/projects-img/cityzen-img4.png" alt="cityzen"/>
                        <img className="sub-img " src="/img/projects-img/cityzen-img5.png" alt="cityzen"/>
                        <img className="sub-img " src="/img/projects-img/cityzen-img6.png" alt="cityzen"/>
                        <img className="sub-img " src="/img/projects-img/cityzen-img7.png" alt="cityzen"/>
                    </div>

                    <div className="team black ">
                        <h2>CAST</h2>
                        <div className="team-members">
                            <div><b>Garik Gyulasaryan</b><br/>
                                Executive Producer
                            </div>
                            <div><b>Narine Ter-Ghevondyan</b><br/>
                                Production Manager
                            </div>
                            <div><b>Ani Karapetyan</b><br/>
                                Spaceman
                            </div>
                            <div><b>Anush Mkhoyann</b><br/>
                                Software Production Manager
                            </div>
                            <div><b>Arsen Vardanyan</b><br/>
                                Production Designer
                            </div>
                            <div><b>David Hunanyan</b><br/>
                                Production Designer
                            </div>
                            <div><b>Gegham Khachatryan</b><br/>
                                Software Producer
                            </div>
                            <div><b>Aram Martirosyan</b><br/>
                                Software Producer
                            </div>
                            <div><b>Armen Papikyan</b><br/>
                                Software Producer
                            </div>
                        </div>

                        <div className="goTo">
                            <a href='http://cityzen.am/hy/' target="_blank" rel="noopener noreferrer">
                                <span>Go To Website</span>
                                <img className="arrow" src="/img/projects-img/arrow.png" alt="arrow"/>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Cityzen;
