import React, { Component } from "react";
// import {isDesktop} from "../../constants/Constants";
import Meta from "../Meta";
import ReactGA from "react-ga";
let isDesktop;
if (process.env.BROWSER) {
  isDesktop = window.matchMedia("(min-width: 812px)").matches;
}
class MusicRoom extends Component {
  componentDidMount() {
    ReactGA.initialize("UA-106993262-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  state = {
    isPlaying: false,
  };

  handlePlay = (e) => {
    this.setState({ isPlaying: true });
    const id = e.target.dataset.id || e.target.id;
    const player = document.getElementById(id);
    console.log(player);
    player.play();
  };

  handlePause = () => {
    this.setState({ isPlaying: false });
  };

  render() {
    const { isPlaying } = this.state;
    return (
      <div className="">
        <Meta
          title={"Music Room - Explore website design story"}
          desc={
            "Musicroom is a one-stop shop for smooth projects facilitation and delivery. The main objective of the company is to unite the interests of the artists and project/event organizers in order to ensure unique, highly engaging and exceptional musical experiences."
          }
          img={"/img/projects-img/"}
        />

        <div className="musicRoom-page">
          <span className="line-circle-elem my-2"></span>
          <div className="musicRoom-text-container mb-2 text-center">
            <h1 className="musicRoom-title">
              <span>The </span>Goal
            </h1>
            We were approached by MusicRoom to create:
            <ul>
              <li>
                A branding that would be solid and elegant, yet vibrant and
                contemporary.
              </li>
              <li>
                A website that would communicate brand concept and philosophy in
                a comprehensive way and engage the visitors with full
                information on artists and projects.
              </li>
              <li>
                An easy-to-use content management system for the whole website.
              </li>
            </ul>
          </div>
          <span className="line-elem mt-2"></span>
          <div className="">
            <img
              className="w-100"
              src="/img/projects-img/musicRoom/musicRoom_01.png"
            />
          </div>
          <div className="musicRoom-player-container">
            <video
              id="musicRoom-player_1"
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              className=""
              poster="/img/projects-img/musicRoom/video_thambnail/01.jpg"
              width="100%"
            >
              <source src="/movie/music_room/01.mp4" type="video/mp4" />
            </video>
            {!isPlaying && (
              <span
                data-id="musicRoom-player_1"
                className="play-btn"
                onClick={this.handlePlay}
              />
            )}
          </div>
          <span className="line-circle-elem"></span>
          <div className="musicRoom-text-container text-center">
            <h1 className="musicRoom-title mb-2">Branding</h1>
            <p className="musicRoom-text">
              The idea behind the brand was to express the elegance of classical
              music while showcasing the vibrance and contemporary feel of the
              company. We’ve experimented with the idea of the room and
              integrated it into the visual identity. This is how the color
              combination and visual identity were born.
            </p>
          </div>
          <span className="line-circle-elem my-4"></span>
          <div className="mx-5 orange-border">
            <img
              className="w-100"
              src="/img/projects-img/musicRoom/musicRoom_02.png"
            />
          </div>
          <div className="musicRoom-player-container">
            <video
              id="musicRoom-player_2"
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              className=""
              poster="/img/projects-img/musicRoom/video_thambnail/02.jpg"
              width="100%"
              
            >
              <source src="/movie/music_room/02.mp4" type="video/mp4" />
            </video>
            {!isPlaying && (
              <span
                data-id="musicRoom-player_2"
                className="play-btn"
                onClick={this.handlePlay}
              />
            )}
          </div>
          <div className="bg-blue pb-5">
            <span className="line-circle-elem mb-2"></span>
            <div className="musicRoom-text-container text-center">
              <h1 className="musicRoom-title mb-2">Animation</h1>
              <p className="musicRoom-text">
                Loading animation, menu animation and scrolling animations were
                designed to provide smooth page-to-page transitions, draw
                attention to clickable sections, and provide enhanced user
                experience.
              </p>
            </div>
          </div>
          <div className="musicRoom-player-container">
            <video
              id="musicRoom-player_3"
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              poster="/img/projects-img/musicRoom/video_thambnail/03.jpg"
              className=""
              width="100%"
            >
              <source src="/movie/music_room/03.mp4" type="video/mp4" />
            </video>
            {!isPlaying && (
              <span
                data-id="musicRoom-player_3"
                className="play-btn"
                onClick={this.handlePlay}
              />
            )}
          </div>
          <div className="musicRoom-player-container">
            <video
              id="musicRoom-player_4"
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              className=""
              poster="/img/projects-img/musicRoom/video_thambnail/04.jpg"
              width="100%"
            >
              <source src="/movie/music_room/04.mp4" type="video/mp4" />
            </video>
            {!isPlaying && (
              <span
                data-id="musicRoom-player_4"
                className="play-btn"
                onClick={this.handlePlay}
              />
            )}
          </div>
          <div className="bg-orange">
            <span className="line-circle-elem blue-line mb-2"></span>
            <div className="musicRoom-text-container w-100 text-center">
              <h1 className="musicRoom-title m-2">The Website</h1>
              <p className="musicRoom-text">
                When designing the website, our main goals were:
                <ul className="w-100 mt-2">
                  <li>Communicating the brand concept & philosophy.</li>
                  <li>
                    Ensuring highly engaging user experience with the help of
                    animations and smooth transitions.
                  </li>
                </ul>
              </p>
            </div>
            <div className="py-5 px-md-5 px-2 d-flex flex-wrap">
              <div className="col-md-6 p-1">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_03_01.png"
                />
              </div>
              <div className="col-md-6 p-1">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_03_02.png"
                />
              </div>
              <div className="col-md-6 p-1">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_03_03.png"
                />
              </div>
              <div className="col-md-6 p-1">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_03_04.png"
                />
              </div>
              <div className="col-md-6 p-1">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_03_05.png"
                />
              </div>
              <div className="col-md-6 p-1">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_03_06.png"
                />
              </div>
            </div>
            <span className="line-elem short-line blue-line mt-4"></span>
          </div>
          <span className="line-circle-elem blue-line mb-2"></span>
          <div className="musicRoom-text-container text-center pb-5">
            <h1 className="musicRoom-title mb-2">Responsive layout</h1>
            <p className="musicRoom-text">
              The website is fully responsive. All the pages are designed and
              developed based on industry best practices, to provide flawless &
              smooth experience.
            </p>
          </div>
          <div className="musicRoom-player-container">
            <video
              id="musicRoom-player_5"
              onPlay={this.handlePlay}
              onPause={this.handlePause}
              poster="/img/projects-img/musicRoom/video_thambnail/05.jpg"
              className=""
              width="100%"
            >
              <source src="/movie/music_room/05.mp4" type="video/mp4" />
            </video>
            {!isPlaying && (
              <span
                data-id="musicRoom-player_5"
                className="play-btn"
                onClick={this.handlePlay}
              />
            )}
          </div>
          <div className="mobile-screenshots-section">
            <div className="px-md-5 w-100 mx-0 row mobile-screenshots-container">
              <div className="py-3 col-md-3 col-6">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_04_01.png"
                />
              </div>
              <div className="py-3 col-md-3 col-6">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_04_02.png"
                />
              </div>
              <div className="py-3 col-md-3 col-6">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_04_03.png"
                />
              </div>
              <div className="py-3 col-md-3 col-6">
                <img
                  className="w-100"
                  src="/img/projects-img/musicRoom/musicRoom_04_04.png"
                />
              </div>
            </div>
          </div>
          <div className="bg-orange admin-panel">
            <div className="musicRoom-text-container w-100 text-center">
              <h1 className="musicRoom-title mb-2">Admin Panel</h1>
              <p className="musicRoom-text">
                Our team created a simple, easy-to-use and functional admin
                panel for the whole website in accordance with Material Design
                guidelines.
                <br /> The admin panel features a simple and elegant interface
                and intuitive design to enable admins to easily create, edit and
                delete projects, connect artists with projects they participate,
                manage the content related to artists as well as fill in
                SEO-related data.
              </p>
            </div>
          </div>
          <div className="">
            <img
              className="w-100"
              src="/img/projects-img/musicRoom/musicRoom_05.png"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MusicRoom;
