import React, { Component, Fragment } from "react";
// import {isDesktop} from "../constants/Constants";
import Stars from "../components/Stars";

let isDesktop;
if (process.env.BROWSER) {
  // global.window = {};
  isDesktop = window.matchMedia("(min-width: 812px)").matches;
}

class ProjectBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      error: false,
      isDesktop: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      this.setState(
        {
          isLoaded: false,
          error: false,
        },
        () => {
          this.image = new Image();
          this.image.src = this.props.bgUrl;
          this.image.onload = this.handleImageLoaded;
          this.image.onerror = this.handleImageError;
        }
      );
    }
  }

  componentDidMount() {
    this.setState({
      isDesktop: window.matchMedia("(min-width: 812px)").matches,
    });
    this.image = new Image();
    this.image.src = this.props.bgUrl;
    this.image.onload = this.handleImageLoaded;
    this.image.onerror = this.handleImageError;
  }

  componentWillUnmount() {
    if (this.image) {
      this.image.onload = function () {};
      delete this.image;
    }
  }

  handleImageLoaded = () => {
    this.setState({ isLoaded: true });
  };

  handleImageError = () => {
    this.setState({
      isLoaded: false,
      error: true,
    });
  };

  render() {
    const { isLoaded, error } = this.state;
    const { bgTitle, title, bgUrl, bgUrlMobile, desc, hideInnerTitle } = this.props;

    return (
      <div className="project-banner">
        <div className="project-banner-bg-wrapper">
          {isDesktop ? (
            <img
              src="/img/codics-icons/stars-line.svg"
              className="stars"
              alt=""
            />
          ) : (
            <div className="codics-stars show">
              <Stars />
            </div>
          )}
          {isLoaded && (
            <Fragment>
              <div
                className="back-img"
                style={{ backgroundImage: `url(${bgUrl})` }}
              />
              {!hideInnerTitle &&
              <div className={"project-banner-bg"}>
                <div>
                  <h1
                    className="project-bgTitle"
                    dangerouslySetInnerHTML={{ __html: bgTitle }}
                  />
                  <div className="lineParent">
                    <div className="line" />
                  </div>
                  <h2
                    className="project-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </div>
              </div>
              }
            </Fragment>
          )}
        </div>

        <div className="project-banner-bg-wrapper mobile-portrait">
          {isLoaded && (
            <Fragment>
              <div
                className="back-img"
                style={{
                  backgroundImage: `url(${bgUrlMobile ? bgUrlMobile : bgUrl})`,
                }}
              />
              <div className="project-banner-bg">
              <div>
                <h1
                  className="project-bgTitle"
                  dangerouslySetInnerHTML={{ __html: bgTitle }}
                />
                <div className="lineParent">
                  <div className="line" />
                </div>
                <h2
                  className="project-title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </div>
            </div>
            </Fragment>
          )}
        </div>

        {desc && (
          <div className="project-story">
            <div className="project-text-wrapper">
              <h3 className="mb-0 py-2">THE STORY</h3>
              <p
                className="project-desc pb-2"
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            </div>
            <img
              className="project-starsLine"
              src="/img/codics-icons/stars-lines.svg"
              alt=""
            />
          </div>
        )}

        <div className="the-hero the-hero-grey">
          <h1>THE HERO</h1>
        </div>
      </div>
    );
  }
}

export default ProjectBanner;
