import React, {Component} from 'react';

class Symbols extends Component {
    render() {
        return (

            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" viewBox="0 0 1409.3 584.3" xmlSpace="preserve">
                <g className="st0 symb-27">
                    <g>
                        <polygon className="st1"
                                 points="1393.9,427.1 279.9,105.1 293.9,101.3 1407.8,423.4 		"/>
                    </g>
                    <g>
                        <polygon className="st2"
                                 points="1380.7,462.8 266.7,140.8 279.9,105.1 1393.9,427.1 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1380.5,462.9 266.5,140.9 266.7,140.8 1380.7,462.8 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1374,518.4 260.1,196.4 260.1,159.6 1374.1,481.7 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1407.8,423.4 1387,478.3 1386.9,515 1374,518.4 1374.1,481.7 1353.5,437.9 1367.5,434.2 1380.5,462.9 1380.7,462.8 1393.9,427.1 		"/>
                    </g>
                    <g>
                        <polygon className="st5"
                                 points="1374.1,481.7 260.1,159.6 239.6,115.9 1353.5,437.9 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1353.5,437.9 239.6,115.9 253.5,112.1 1367.5,434.2 		"/>
                    </g>
                </g>
                <g className="st0 symb-14">
                    <g>
                        <polygon className="st1"
                                 points="1400.5,348.7 286.6,26.6 295.3,24.3 1409.3,346.3 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1400.6,336.1 286.6,14.1 293.5,12.2 1407.4,334.3 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1409.3,322 1409.3,327.1 1400.6,329.4 1400.6,336.1 1407.4,334.3 1407.4,339.3 1400.6,341.1 1400.5,348.7 1409.3,346.3 1409.3,351.3 1396.1,354.9 1396.2,325.5 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1396.1,354.9 282.2,32.8 282.3,3.5 1396.2,325.5 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1396.2,325.5 282.3,3.5 295.4,0 1409.3,322 		"/>
                    </g>
                </g>
                <g className="st0 symb-13">
                    <g>
                        <polygon className="st3"
                                 points="1386.5,352.4 272.5,30.4 272.5,11.1 1386.5,333.2 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1378.2,354.7 264.2,32.6 272.5,30.4 1386.5,352.4 		"/>
                    </g>
                    <g>
                        <path className="st4"
                              d="M1387.3,327.9l3.6,1.8l-0.1,23.7l-3.6,3.8l-13.4,3.6l0.1-29.3L1387.3,327.9z M1386.5,352.4l0-19.3l-8.3,2.2 l0,19.3L1386.5,352.4"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1373.8,360.9 259.8,38.8 259.9,9.5 1373.8,331.5 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1373.8,331.5 259.9,9.5 273.3,5.9 1387.3,327.9 		"/>
                    </g>
                </g>
                <g className="st0 symb-12">
                    <g>
                        <polygon className="st3"
                                 points="1364.1,358.6 250.1,36.6 250.2,16.9 1364.1,339 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1355.8,360.9 241.8,38.8 250.1,36.6 1364.1,358.6 		"/>
                    </g>
                    <g>
                        <polygon className="st6"
                                 points="1355,366.1 241.1,44 237.5,42.2 1351.4,364.2 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1355.1,336.4 241.1,14.3 250.9,11.7 1364.9,333.7 		"/>
                    </g>
                    <g>
                        <path className="st4"
                              d="M1364.9,333.7l3.6,1.8l-0.1,24.1l-3.6,3.8l-9.8,2.6l-3.6-1.8l0.1-24.1l3.6-3.8L1364.9,333.7z M1364.1,358.6 l0-19.7l-8.3,2.2l0,19.7L1364.1,358.6"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1351.4,364.2 237.5,42.2 237.5,18.1 1351.5,340.1 		"/>
                    </g>
                    <g>
                        <polygon className="st7"
                                 points="1351.5,340.1 237.5,18.1 241.1,14.3 1355.1,336.4 		"/>
                    </g>
                </g>
                <g className="st0 symb-26">
                    <g>
                        <polygon className="st5"
                                 points="1333.3,529.3 219.4,207.3 205.6,178.5 1319.6,500.6 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1331.4,482.7 217.4,160.6 217.5,136.5 1331.4,458.6 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1311.6,488 197.7,165.9 217.4,160.6 1331.4,482.7 		"/>
                    </g>
                    <g>
                        <path className="st4"
                              d="M1333.6,496.8l13.9,28.7l-14.1,3.8l-13.7-28.8l-8,2.1l-0.1,32.5l-12.9,3.4l0.2-86.1l34.8-9.3l10.6,5.4 l-0.1,37.1L1333.6,496.8z M1331.4,482.7l0.1-24.1l-19.8,5.3l-0.1,24.1L1331.4,482.7"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1298.7,538.6 184.7,216.6 184.9,130.5 1298.9,452.6 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1298.9,452.6 184.9,130.5 219.7,121.2 1333.7,443.2 		"/>
                    </g>
                </g>
                <g className="st0 symb-11">
                    <g>
                        <polygon className="st3"
                                 points="1342,364.5 228.1,42.5 228.1,38.7 1342,360.8 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1342,360.8 228.1,38.7 232.5,37.6 1346.4,359.6 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1342.1,348.6 228.1,26.6 228.1,22.8 1342.1,344.9 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1334.7,366.5 220.7,44.5 228.1,42.5 1342,364.5 		"/>
                    </g>
                    <g>
                        <polygon className="st6"
                                 points="1333.9,371.7 220,49.7 216.3,47.9 1330.3,369.9 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1334,342 220,20 228.9,17.6 1342.8,339.6 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1330.3,369.9 216.3,47.9 216.4,23.8 1330.3,345.8 		"/>
                    </g>
                    <g>
                        <polygon className="st7" points="1330.3,345.8 216.4,23.8 220,20 1334,342 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1342.8,339.6 1346.5,341.5 1346.4,347.5 1342.1,348.6 1342.1,344.9 1334.7,346.8 1334.7,366.5 1342,364.5 1342,360.8 1346.4,359.6 1346.4,365.6 1342.8,369.4 1333.9,371.7 1330.3,369.9 1330.3,345.8 1334,342 		"/>
                    </g>
                </g>
                <g className="st0 symb-10">
                    <g>
                        <polygon className="st3"
                                 points="1312.5,377.3 198.5,55.3 198.5,42.7 1312.5,364.7 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1312.5,359.7 198.5,37.7 198.6,25.9 1312.5,348 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1312.5,348 198.6,25.9 202.9,24.8 1316.9,346.8 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1303.9,362 189.9,40 198.5,37.7 1312.5,359.7 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1316.9,346.8 1316.8,376.1 1312.5,377.3 1312.5,364.7 1303.9,367 1303.8,379.6 1299.5,380.8 1299.5,351.4 1303.9,350.3 1303.9,362 1312.5,359.7 1312.5,348 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1299.5,380.8 185.5,58.7 185.6,29.4 1299.5,351.4 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1299.5,351.4 185.6,29.4 190,28.2 1303.9,350.3 		"/>
                    </g>
                </g>
                <g className="st0 symb-25">
                    <g>
                        <polygon className="st3"
                                 points="1270.2,532.1 156.3,210.1 156.4,152.3 1270.3,474.3 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1245.9,538.6 131.9,216.6 156.3,210.1 1270.2,532.1 		"/>
                    </g>
                    <g>
                        <polygon className="st6" points="1243.6,554 129.7,232 119,226.6 1233,548.6 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1243.8,466.7 129.9,144.7 158.6,137 1272.6,459 		"/>
                    </g>
                    <g>
                        <path className="st4"
                              d="M1272.6,459l10.6,5.4l-0.2,70.8l-10.7,11.1l-28.8,7.7l-10.6-5.4l0.2-70.8l10.7-11.1L1272.6,459z M1270.2,532.1l0.1-57.8l-24.3,6.5l-0.1,57.8L1270.2,532.1"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1233,548.6 119,226.6 119.2,155.8 1233.1,477.8 		"/>
                    </g>
                    <g>
                        <polygon className="st7"
                                 points="1233.1,477.8 119.2,155.8 129.9,144.7 1243.8,466.7 		"/>
                    </g>
                </g>
                <g className="st0 symb-9">
                    <g>
                        <polygon className="st3"
                                 points="1290.1,378.5 176.1,56.4 176.1,52.7 1290.1,374.7 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1290.1,374.7 176.1,52.7 180.5,51.5 1294.5,373.5 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1290.1,362.5 176.2,40.5 176.2,36.7 1290.1,358.8 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1282.7,380.4 168.8,58.4 176.1,56.4 1290.1,378.5 		"/>
                    </g>
                    <g>
                        <polygon className="st6"
                                 points="1282,385.7 168,63.6 164.4,61.8 1278.3,383.8 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1282,355.9 168.1,33.9 176.9,31.5 1290.9,353.6 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1278.3,383.8 164.4,61.8 164.4,37.7 1278.4,359.7 		"/>
                    </g>
                    <g>
                        <polygon className="st7"
                                 points="1278.4,359.7 164.4,37.7 168.1,33.9 1282,355.9 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1290.9,353.6 1294.5,355.4 1294.5,361.4 1290.1,362.5 1290.1,358.8 1282.8,360.7 1282.7,380.4 1290.1,378.5 1290.1,374.7 1294.5,373.5 1294.5,379.5 1290.8,383.3 1282,385.7 1278.3,383.8 1278.4,359.7 1282,355.9 		"/>
                    </g>
                </g>
                <g className="st0 symb-22">
                    <g>
                        <polygon className="st8"
                                 points="1271.5,438.5 157.5,116.4 155.9,110.6 1269.9,432.6 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1263,429.4 149,107.4 154.6,105.9 1268.5,427.9 		"/>
                    </g>
                    <g>
                        <polygon className="st9"
                                 points="1268.5,427.9 154.6,105.9 151.9,96 1265.8,418.1 		"/>
                    </g>
                    <g>
                        <path className="st4"
                              d="M1268.1,410.1l7.8,27.2l-4.3,1.2l-1.6-5.9l-8.2,2.2l-1.7,6.8l-4.3,1.2l7.9-31.4L1268.1,410.1z M1263,429.4 l5.5-1.5l-2.7-9.9l-0.1,0L1263,429.4"/>
                    </g>
                    <g>
                        <polygon className="st10"
                                 points="1255.7,442.7 141.7,120.7 149.6,89.3 1263.6,411.3 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1263.6,411.3 149.6,89.3 154.1,88.1 1268.1,410.1 		"/>
                    </g>
                </g>
                <g className="st0 symb-8">
                    <g>
                        <polygon className="st8"
                                 points="1270.9,388.4 156.9,66.4 155.3,60.5 1269.3,382.5 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1262.4,379.4 148.4,57.3 154,55.8 1267.9,377.9 		"/>
                    </g>
                    <g>
                        <polygon className="st9" points="1267.9,377.9 154,55.8 151.3,46 1265.2,368 		"/>
                    </g>
                    <g>
                        <path className="st4"
                              d="M1267.5,360l7.8,27.2l-4.3,1.2l-1.6-5.9l-8.2,2.2l-1.7,6.8l-4.3,1.2l7.9-31.4L1267.5,360z M1262.4,379.4 l5.5-1.5l-2.7-9.9l-0.1,0L1262.4,379.4"/>
                    </g>
                    <g>
                        <polygon className="st10"
                                 points="1255.1,392.7 141.1,70.6 149,39.2 1263,361.2 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1263,361.2 149,39.2 153.5,38 1267.5,360 		"/>
                    </g>
                </g>
                <g className="st0 symb-21">
                    <g>
                        <polygon className="st3"
                                 points="1240,442.1 126.1,120.1 126.1,112.8 1240.1,434.8 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1240.1,425.8 126.1,103.7 126.1,100.4 1240.1,422.4 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1233.3,443.9 119.4,121.9 126.1,120.1 1240,442.1 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1233.7,431.1 119.7,109.1 126.9,107.2 1240.8,429.2 		"/>
                    </g>
                    <g>
                        <polygon className="st6"
                                 points="1232.6,449.1 118.6,127.1 115,125.2 1228.9,447.3 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1228.9,447.3 115,125.2 115,119.7 1228.9,441.7 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1228.9,441.7 115,119.7 119.4,118.5 1233.3,440.5 		"/>
                    </g>
                    <g>
                        <polygon className="st6"
                                 points="1232.9,436.8 118.9,114.7 115.3,112.9 1229.3,434.9 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1232.9,419.3 119,97.3 126.9,95.1 1240.8,417.2 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1229.3,434.9 115.3,112.9 115.3,101 1229.3,423.1 		"/>
                    </g>
                    <g>
                        <polygon className="st7"
                                 points="1229.3,423.1 115.3,101 119,97.3 1232.9,419.3 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1240.8,417.2 1244.5,419 1244.5,424.6 1240.1,425.8 1240.1,422.4 1233.7,424.1 1233.7,431.1 1240.8,429.2 1244.4,431 1244.4,443.1 1240.8,446.9 1232.6,449.1 1228.9,447.3 1228.9,441.7 1233.3,440.5 1233.3,443.9 1240,442.1 1240.1,434.8 1232.9,436.8 1229.3,434.9 1229.3,423.1 1232.9,419.3 		"/>
                    </g>
                </g>
                <g className="st0 symb-7">
                    <g>
                        <polygon className="st1"
                                 points="1244.5,390.5 130.5,68.4 139.2,66.1 1253.2,388.1 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1244.5,377.9 130.5,55.9 137.4,54.1 1251.3,376.1 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1253.3,363.8 1253.2,368.9 1244.5,371.2 1244.5,377.9 1251.3,376.1 1251.3,381.1 1244.5,382.9 1244.5,390.5 1253.2,388.1 1253.2,393.2 1240.1,396.7 1240.1,367.4 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1240.1,396.7 126.1,74.6 126.2,45.3 1240.1,367.4 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1240.1,367.4 126.2,45.3 139.3,41.8 1253.3,363.8 		"/>
                    </g>
                </g>
                <g className="st0 symb-24">
                    <g>
                        <polygon className="st3"
                                 points="1192.6,567 78.7,245 78.8,173.7 1192.8,495.7 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1223.1,472.9 1223,487.6 1205.7,492.3 1205.5,563.6 1192.6,567 1192.8,495.7 1175.4,500.4 1175.5,485.6 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1175.4,500.4 61.5,178.3 61.5,163.6 1175.5,485.6 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1175.5,485.6 61.5,163.6 109.1,150.8 1223.1,472.9 		"/>
                    </g>
                </g>
                <g className="st0 symb-20">
                    <g>
                        <polygon className="st4"
                                 points="1224,421.9 1223.9,451.2 1219.5,452.4 1219.6,423.1 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1219.5,452.4 105.6,130.4 105.6,101 1219.6,423.1 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1219.6,423.1 105.6,101 110,99.9 1224,421.9 		"/>
                    </g>
                </g>
                <g className="st0 symb-6">
                    <g>
                        <polygon className="st3"
                                 points="1222.3,396.4 108.3,74.4 108.3,55.1 1222.3,377.2 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1214,398.7 100,76.6 108.3,74.4 1222.3,396.4 		"/>
                    </g>
                    <g>
                        <path className="st4"
                              d="M1223.1,371.9l3.6,1.8l-0.1,23.7l-3.6,3.8l-13.4,3.6l0.1-29.3L1223.1,371.9z M1222.3,396.4l0-19.3l-8.3,2.2 l0,19.3L1222.3,396.4"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1209.6,404.8 95.6,82.8 95.7,53.5 1209.6,375.5 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1209.6,375.5 95.7,53.5 109.1,49.9 1223.1,371.9 		"/>
                    </g>
                </g>
                <g className="st0 symb-19">
                    <g>
                        <polygon className="st1"
                                 points="1198.6,453 84.7,130.9 93.4,128.6 1207.3,450.6 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1198.6,440.4 84.7,118.4 91.5,116.5 1205.5,438.6 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1207.4,426.3 1207.4,431.4 1198.7,433.7 1198.6,440.4 1205.5,438.6 1205.5,443.6 1198.6,445.4 1198.6,453 1207.3,450.6 1207.3,455.7 1194.2,459.2 1194.3,429.9 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1194.2,459.2 80.3,137.1 80.3,107.8 1194.3,429.9 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1194.3,429.9 80.3,107.8 93.4,104.3 1207.4,426.3 		"/>
                    </g>
                </g>
                <g className="st0 symb-18">
                    <g>
                        <polygon className="st5"
                                 points="1185.2,461.6 71.3,139.6 66.6,129.8 1180.5,451.8 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1184.6,445.7 70.6,123.7 70.6,115.4 1184.6,437.5 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1177.8,447.5 63.9,125.5 70.6,123.7 1184.6,445.7 		"/>
                    </g>
                    <g>
                        <path className="st4"
                              d="M1185.3,450.5l4.7,9.8l-4.8,1.3l-4.7-9.8l-2.7,0.7l0,11.1l-4.4,1.2l0.1-29.3l11.9-3.2l3.6,1.8l0,12.6 L1185.3,450.5z M1184.6,445.7l0-8.2l-6.7,1.8l0,8.2L1184.6,445.7"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1173.4,464.7 59.5,142.7 59.5,113.4 1173.5,435.4 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1173.5,435.4 59.5,113.4 71.4,110.2 1185.3,432.3 		"/>
                    </g>
                </g>
                <g className="st0 symb-5">
                    <g>
                        <polygon className="st3"
                                 points="1199.8,397.1 85.9,75.1 86,56.1 1199.9,378.1 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1199.9,378.1 86,56.1 90.4,54.9 1204.3,377 		"/>
                    </g>
                    <g>
                        <polygon className="st6"
                                 points="1200.2,407.4 86.2,85.3 76.8,68.9 1190.7,390.9 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1204.3,377 1204.3,406.3 1200.2,407.4 1190.7,390.9 1190.7,391 1190.6,409.9 1186.3,411.1 1186.3,381.8 1190.4,380.7 1199.8,397.1 1199.9,378.1 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1186.3,411.1 72.3,89.1 72.4,59.8 1186.3,381.8 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1186.3,381.8 72.4,59.8 76.5,58.7 1190.4,380.7 		"/>
                    </g>
                </g>
                <g className="st0 symb-4">
                    <g>
                        <polygon className="st4"
                                 points="1181,383.2 1180.9,412.5 1176.6,413.7 1176.6,384.4 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1176.6,413.7 62.6,91.7 62.7,62.3 1176.6,384.4 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1176.6,384.4 62.7,62.3 67.1,61.2 1181,383.2 		"/>
                    </g>
                </g>
                <g className="st0 symb-23">
                    <g>
                        <polygon className="st3"
                                 points="1152.3,563.7 38.4,241.7 38.4,220.4 1152.4,542.4 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1152.4,515.8 38.5,193.7 38.5,183.9 1152.5,505.9 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1132.6,569 18.6,247 38.4,241.7 1152.3,563.7 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1133.6,531.5 19.6,209.5 40.7,203.8 1154.6,525.9 		"/>
                    </g>
                    <g>
                        <polygon className="st6"
                                 points="1130.3,584.3 16.4,262.3 5.7,256.9 1119.7,579 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1119.7,579 5.7,256.9 5.8,240.6 1119.7,562.6 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1119.7,562.6 5.8,240.6 18.6,237.1 1132.6,559.2 		"/>
                    </g>
                    <g>
                        <polygon className="st6"
                                 points="1131.3,548.1 17.4,226 6.7,220.7 1120.7,542.7 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1131.5,496.8 17.5,174.8 40.7,168.5 1154.7,490.6 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1120.7,542.7 6.7,220.7 6.8,185.9 1120.8,507.9 		"/>
                    </g>
                    <g>
                        <polygon className="st7"
                                 points="1120.8,507.9 6.8,185.9 17.5,174.8 1131.5,496.8 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1154.7,490.6 1165.3,496 1165.3,512.3 1152.4,515.8 1152.5,505.9 1133.6,511 1133.6,531.5 1154.6,525.9 1165.3,531.2 1165.2,566.8 1154.5,577.9 1130.3,584.3 1119.7,579 1119.7,562.6 1132.6,559.2 1132.6,569 1152.3,563.7 1152.4,542.4 1131.3,548.1 1120.7,542.7 1120.8,507.9 1131.5,496.8 		"/>
                    </g>
                </g>
                <g className="st0 symb-17">
                    <g>
                        <polygon className="st1"
                                 points="1160.6,463.2 46.7,141.1 55.4,138.8 1169.4,460.8 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1160.7,450.6 46.7,128.6 53.6,126.7 1167.5,448.7 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1169.4,436.5 1169.4,441.5 1160.7,443.9 1160.7,450.6 1167.5,448.7 1167.5,453.8 1160.6,455.6 1160.6,463.2 1169.4,460.8 1169.4,465.8 1156.2,469.3 1156.3,440 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1156.2,469.3 42.3,147.3 42.3,118 1156.3,440 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1156.3,440 42.3,118 55.5,114.5 1169.4,436.5 		"/>
                    </g>
                </g>
                <g className="st0 symb-3">
                    <g>
                        <polygon className="st3"
                                 points="1166.9,416.3 52.9,94.3 52.9,81.7 1166.9,403.7 		"/>
                    </g>
                    <g>
                        <polygon className="st3" points="1166.9,398.7 52.9,76.7 53,64.9 1166.9,387 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1166.9,387 53,64.9 57.4,63.8 1171.3,385.8 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1158.3,401 44.3,79 52.9,76.7 1166.9,398.7 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1171.3,385.8 1171.2,415.1 1166.9,416.3 1166.9,403.7 1158.3,406 1158.3,418.6 1153.9,419.8 1153.9,390.5 1158.3,389.3 1158.3,401 1166.9,398.7 1166.9,387 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1153.9,419.8 39.9,97.7 40,68.4 1153.9,390.5 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1153.9,390.5 40,68.4 44.4,67.3 1158.3,389.3 		"/>
                    </g>
                </g>
                <g className="st0 symb-16">
                    <g>
                        <polygon className="st3"
                                 points="1146.5,471.9 32.6,149.9 32.6,137.4 1146.6,459.4 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1146.6,454.4 32.6,132.3 32.6,120.6 1146.6,442.6 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1146.6,442.6 32.6,120.6 37,119.4 1151,441.5 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1138,456.7 24,134.6 32.6,132.3 1146.6,454.4 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1151,441.5 1150.9,470.8 1146.5,471.9 1146.6,459.4 1138,461.7 1137.9,474.3 1133.6,475.4 1133.6,446.1 1138,444.9 1138,456.7 1146.6,454.4 1146.6,442.6 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1133.6,475.4 19.6,153.4 19.7,124.1 1133.6,446.1 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1133.6,446.1 19.7,124.1 24,122.9 1138,444.9 		"/>
                    </g>
                </g>
                <g className="st0 symb-2">
                    <g>
                        <polygon className="st1"
                                 points="1141.1,418.2 27.1,96.1 35.9,93.8 1149.8,415.8 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1141.1,405.6 27.2,83.6 34,81.7 1148,403.8 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1149.9,391.5 1149.9,396.6 1141.1,398.9 1141.1,405.6 1148,403.8 1148,408.8 1141.1,410.6 1141.1,418.2 1149.8,415.8 1149.8,420.9 1136.7,424.4 1136.8,395.1 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1136.7,424.4 22.7,102.3 22.8,73 1136.8,395.1 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1136.8,395.1 22.8,73 35.9,69.5 1149.9,391.5 		"/>
                    </g>
                </g>
                <g className="st0 symb-15">
                    <g>
                        <polygon className="st3"
                                 points="1119.8,479.1 5.9,157.1 5.9,132.8 1119.9,454.8 		"/>
                    </g>
                    <g>
                        <polygon className="st4"
                                 points="1130.2,447 1130.2,452.1 1124.3,453.6 1124.2,477.9 1119.8,479.1 1119.9,454.8 1114,456.4 1114,451.4 		"/>
                    </g>
                    <g>
                        <polygon className="st3" points="1114,456.4 0,134.4 0,129.3 1114,451.4 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1114,451.4 0,129.3 16.2,125 1130.2,447 		"/>
                    </g>
                </g>
                <g className="st0 symb-1">
                    <g>
                        <polygon className="st3" points="1127,421.9 13.1,99.9 13.1,92.3 1127,414.3 		"/>
                    </g>
                    <g>
                        <polygon className="st3"
                                 points="1127,409.3 13.1,87.3 13.1,80.7 1127.1,402.7 		"/>
                    </g>
                    <g>
                        <polygon className="st1"
                                 points="1120.3,423.8 6.3,101.7 13.1,99.9 1127,421.9 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1120.3,411.1 6.4,89.1 13.1,87.3 1127,409.3 		"/>
                    </g>
                    <g>
                        <path className="st4"
                              d="M1131.4,408.5l-2.7,2.8l2.7,1.4l0,10.2l-3.6,3.8l-11.9,3.2l0.1-29.3l11.9-3.2l3.6,1.8L1131.4,408.5z M1127,421.9l0-7.6l-6.7,1.8l0,7.6L1127,421.9 M1127,409.3l0-6.6l-6.7,1.8l0,6.6L1127,409.3"/>
                    </g>
                    <g>
                        <polygon className="st3" points="1115.9,429.9 1.9,107.9 2,78.6 1116,400.6 		"/>
                    </g>
                    <g>
                        <polygon className="st1" points="1116,400.6 2,78.6 13.9,75.4 1127.8,397.5 		"/>
                    </g>
                </g>
            </svg>
        )
    }
}

export default Symbols;
