import React from 'react';
import Helmet from 'react-helmet'
class Meta extends React.Component {
    render(){
        const {title, img, desc} = this.props;
        return(
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={desc}/>
                <meta property="og:image" content={img}/>

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={title}/>
                <meta name="twitter:description" content={desc}/>
                <meta name="twitter:image" content={img}/>
            </Helmet>
        )
    }
}
export default Meta;
