import React, {Component} from 'react';

class HomeProjectInfo extends Component {
    title = '';
    bool = false;

    render() {
        return (
            <>
                <h2 className="home-slider-projects-title">Projects</h2>
                <h1 className={this.bool ? "home-slider-projectBgTitle aa" : "home-slider-projectBgTitle " } id="sliderBgTitle">{this.title}</h1>
            </>
        )
    }
}

export default HomeProjectInfo;
