import React, { Component } from "react";
import Swiper from "react-id-swiper";
// import {Navigation, Pagination, Scrollbar} from "swiper/js/swiper.esm";
import CodicsTop from "./CodicsTop";
import projects from "../data/projects";
import { Link } from "react-router-dom";
import Stars from "./Stars";
// import {isDesktop} from './../constants/Constants';
import HomeProjectInfo from "./HomeProjectInfo";

let isDesktop;
if (process.env.BROWSER) {
  isDesktop = window.matchMedia("(min-width: 812px)").matches;
}

class MySlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStars: false,
      slide: this.props.slideNumber,
      link: true,
      hash: false,
      acceptSlideTitle: false,
      currentSlideNumber: 0,
      isMobile: false,
      bgTitle: "",
    };

    this.swiperMain = null;
    this.swiperMainParams = {
      containerModifierClass: "bigSwiper-",
      wrapperClass: "bigSwiper-wrapper",
      mousewheel: {
        invert: false,
        eventsTarged: ".scrollSwiper-0",
      },
      nested: false,
      speed: 1800,
      direction: "vertical",
      allowTouchMove: !isDesktop,
      getSwiper: this.swiperMainInstance,
      slideActiveClass: "bigSwiper-slide-active",
      slidePrevClass: "bigSwiper-slide-prev",
      slideNextClass: "bigSwiper-slide-next",
      initialSlide: this.props.startProjects ? 1 : 0,
      allowSlidePrev: true,
      allowSlideNext: true,
      releaseOnEdges: true,
      autoHeight: true,
    };

    this.swiperNested = null;
    this.swiperNestedParams = {
      containerModifierClass: "mySwiper-",
      slideActiveClass: "mySwiper-slide-active",
      slideNextClass: "mySwiper-slide-next",
      slidePrevClass: "mySwiper-slide-prev",
      mousewheel: {
        invert: false,
      },
      hashNavigation: true,
      replaceState: true,
      // freeMode: true,
      spaceBetween: 0,
      speed: 1800,
      getSwiper: this.swiperNestedInstance,
      direction: "vertical",
      allowTouchMove: !isDesktop,
      scrollbar: {
        el: ".swiper-scrollbar",
        draggable: isDesktop,
        dragSize: isDesktop ? 21 : 10,
      },
      nested: true,
      initialSlide: this.props.slideNumber,
      // allowSlidePrev: this.props.startProjects,
      // allowSlideNext: this.props.startProjects,
      allowSlidePrev: true,
      allowSlideNext: true,
      releaseOnEdges: true,
      autoHeight: true,
    };

    this.homeIndex = this.props.startProjects ? 1 : 0;
  }

  componentDidMount() {
    const isMobile = window.innerWidth <= 812 ? true : false;
    this.setState({
      showStars: true,
      isMobile,
      bgTitle: projects.data[0].bgTitle,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.toTop !== this.props.toTop) {
      this.homeToTop();
    }

    if (prevProps.toProject !== this.props.toProject) {
      this.homeToProjects();
    }

    if (prevProps.count !== this.props.count) {
      if (this.props.startProjects) {
        if (this.props.count >= 100) {
          const state = {};
          this.props.history.replace({ ...this.props.history.location, state });

          [...document.querySelectorAll(".codics-scroll")].forEach((e) =>
            e.classList.add("change")
          );
          document.getElementById("myHeader").classList.add("showFast");
          setTimeout(() => {
            [...document.querySelectorAll(".myImg")].forEach((e) =>
              e.classList.add("showSlide")
            );
          }, 500);
        }
      } else {
        [...document.querySelectorAll(".myImg")].forEach((e) =>
          e.classList.add("showSlide")
        );
      }
    }
  }

  toggleSymbols = (isEnable) => {
    let element = document.getElementById("symbolsContainer").classList;
    isEnable ? element.add("showSymbols") : element.remove("showSymbols");
  };

  swiperMainInstance = (props) => {
    this.swiperMain = props;
    const { swiperMain } = this;

    if (swiperMain !== null) {
      this.props.startProjects &&
        document.getElementById("myHeader").classList.add("moveLeft");

      swiperMain.on("transitionStart", () => {
        this.swiperNested.allowSlideNext = false;
        swiperMain.realIndex
          ? this.toggleSymbols(false)
          : this.toggleSymbols(true);

        // if (window.location.hash === "") {
        //   setTimeout(() => {
        //     this.props.location.hash = "wcit";
        //     window.location.hash = "wcit";
        //   }, 1000);
        // }

        if (swiperMain.realIndex === 1) {
          [...document.querySelectorAll(".codics-scroll")].forEach((e) =>
            e.classList.add("change")
          );
          document.getElementById("myHeader").classList.add("moveLeft");

          // if (this.state.acceptSlideTitle) {
          //   setTimeout(() => {
          //     document
          //       .getElementById("sliderBgTitle")
          //       .classList.add("show", "slideUp");
          //     document
          //       .getElementsByClassName("home-slider-projects-title")[0]
          //       .classList.add("show");
          //     document.getElementById("sliderBgTitle").innerText =
          //       projects.data[this.state.currentSlideNumber].bgTitle;
          //   }, 300);
          // } else {
          //   setTimeout(() => {
          //     document
          //       .getElementById("sliderBgTitle")
          //       .classList.add("show", "slideUp");
          //     document
          //       .getElementsByClassName("home-slider-projects-title")[0]
          //       .classList.add("show");
          //     document.getElementById("sliderBgTitle").innerText =
          //       projects.data[0].bgTitle;
          //   }, 300);
          // }
        } else {
          // setTimeout(() => {
          //   this.props.location.hash = "";
          //   window.location.hash = "";
          // }, 2000);

          // setTimeout(() => {
          //   document.getElementById("sliderBgTitle").classList.remove("show");
          //   document
          //     .getElementsByClassName("home-slider-projects-title")[0]
          //     .classList.remove("show");
          // }, 401);

          [...document.querySelectorAll(".codics-scroll")].forEach((e) =>
            e.classList.remove("change")
          );
          document.getElementById("myHeader").classList.remove("moveLeft");
        }
      });

      swiperMain.on("transitionEnd", () => {
        this.swiperNested.allowSlideNext = true;
        this.state.isMobile &&
          document.getElementById("mobile-bg-title").classList.add("show");
        if (swiperMain.realIndex === 0) {
          this.setNestedSwiperStatus(false);
        } else {
          this.setNestedSwiperStatus(true);
        }
      });
      swiperMain.on("slideChange", () => {
        this.homeIndex = swiperMain.realIndex;
      });
    }
  };

  swiperNestedInstance = (props) => {
    this.swiperNested = props;
    const { swiperNested } = this;

    if (swiperNested !== null) {
      swiperNested.el.classList.add("notTouched");
      this.props.passValue(swiperNested.realIndex);

      this.setState({
        acceptSlideTitle: true,
        currentSlideNumber: swiperNested.realIndex,
      });

      swiperNested.on("scrollbarDragStart", () => {
        // swiper.removeSlide(0)
        swiperNested.wrapperEl.style.webkitTransform =
          "translate3d(0px," +
          swiperNested.translate * 0.3 +
          "px,0px) scale(0.5)";
        swiperNested.wrapperEl.style.transform =
          "translate3d(0px," +
          swiperNested.translate * 0.3 +
          "px,0px) scale(0.5)";
        swiperNested.wrapperEl.style.transitionDuration = "500ms";
        swiperNested.wrapperEl.style.webkitTransitionDuration = "500ms";
        swiperNested.el.classList.remove("notTouched");
        swiperNested.el.classList.add("isTouched");
        // document.getElementById('codicsStars').src = '/img/codics-icons/stars-small.svg'
        // console.log(document.getElementById('codicsStars').src);
        // document.getElementById("sliderBgTitle").classList.remove("show");
        // document
        //   .getElementsByClassName("home-slider-projects-title")[0]
        //   .classList.remove("show");
      });
      swiperNested.on("scrollbarDragMove", () => {
        swiperNested.wrapperEl.style.webkitTransform =
          "translate3d(0px," +
          swiperNested.translate * 0.3 +
          "px,0px) scale(0.5)";
        swiperNested.wrapperEl.style.transform =
          "translate3d(0px," +
          swiperNested.translate * 0.3 +
          "px,0px) scale(0.5)";
        swiperNested.wrapperEl.style.transitionDuration = "500ms";
        swiperNested.wrapperEl.style.webkitTransitionDuration = "500ms";
        swiperNested.el.classList.remove("notTouched");
        swiperNested.el.classList.add("isTouched");
        // document.getElementById('codicsStars').src = '/img/codics-icons/stars-small.svg';
        // document.getElementById("sliderBgTitle").classList.remove("show");
        // document
        //   .getElementsByClassName("home-slider-projects-title")[0]
        //   .classList.remove("show");
      });
      swiperNested.on("scrollbarDragEnd", () => {
        // swiperNested.prependSlide(`${CodicsTop}`)
        swiperNested.wrapperEl.style.webkitTransform =
          "translate3d(0px," + swiperNested.translate + "px,0px) scale(1)";
        swiperNested.wrapperEl.style.transform =
          "translate3d(0px," + swiperNested.translate + "px,0px) scale(1)";
        // swiper.wrapperEl.style.transitionDuration = '500ms';
        // swiper.wrapperEl.style.webkitTransitionDuration = '500ms';
        // document.getElementById('codicsStars').src = '/img/codics-icons/stars-big.svg'
        swiperNested.el.classList.add("notTouched");
        swiperNested.el.classList.remove("isTouched");
        // this.homeIndex !== 0 &&
        // document.getElementById("sliderBgTitle").classList.add("show");
        // document
        //   .getElementsByClassName("home-slider-projects-title")[0]
        //   .classList.add("show");
      });

      swiperNested.on("transitionStart", () => {
        false && this.setMainSwiperStatus(false);
        this.swiperNestedParams.slideToClickedSlide = true;
        const idx = this.swiperNested.activeIndex;
        this.state.isMobile &&
          document.getElementById("mobile-bg-title").classList.remove("show");
        setTimeout(() => {
          this.state.isMobile &&
            document.getElementById("mobile-bg-title").classList.add("show");
          this.setState({
            bgTitle: projects?.data[idx]?.bgTitle,
          });
        }, 801);

        //##########################
        this.props.passValue(swiperNested.realIndex);
        //##########################

        // swiperNested.update();
        this.setState({
          link: false,
        });

        if (this.homeIndex === 0) {
          swiperNested.realIndex !== 0 && swiperNested.slideTo(0, 0, false);
          swiperNested.wrapperEl.style.webkitTransform =
            "translate3d(0px," + swiperNested.translate + "px,0px) scale(1)";
          swiperNested.wrapperEl.style.transform =
            "translate3d(0px," + swiperNested.translate + "px,0px) scale(1)";
          swiperNested.wrapperEl.style.transitionDuration = "1500ms";
          swiperNested.wrapperEl.style.webkitTransitionDuration = "1500ms";
        }

        // document.getElementById("sliderBgTitle").classList.remove("show");
        // document
        //   .getElementsByClassName("home-slider-projects-title")[0]
        //   .classList.remove("show");
        // setTimeout(() => {
        //   this.homeIndex !== 0 &&
        //     document.getElementById("sliderBgTitle").classList.add("show");
        //   document
        //     .getElementsByClassName("home-slider-projects-title")[0]
        //     .classList.add("show");
        // }, 400);
        // setTimeout(function () {
        //   document.getElementById("sliderBgTitle").innerText =
        //     projects.data[swiperNested.realIndex].bgTitle;
        // }, 300);

        // swiperNested.wrapperEl.style.webkitTransform = 'translate3d(0px,' + swiperNested.translate + 'px,0px) scale(0.3)';
        // swiperNested.wrapperEl.style.transform = 'translate3d(0px,' + swiperNested.translate + 'px,0px) scale(0.3)';
      });

      swiperNested.on("transitionEnd", () => {
        // PLAN B solution ##########################
        // setTimeout(() => {
        //     this.homeIndex !== 0 && document.getElementById('sliderBgTitle').classList.add('show');
        //     document.getElementsByClassName('home-slider-projects-title')[0].classList.add('show');
        // }, 100);
        // ##########################################
        if (swiperNested.realIndex === 0) {
          this.setMainSwiperStatus(true);
        } else {
          this.setMainSwiperStatus(false);
        }

        this.setState({
          link: true,
        });
        this.swiperNestedParams.slideToClickedSlide = false;
      });

      // swiperNested.on('touchStart', () => {
      //     console.log('touchStart');
      //     swiperNested.wrapperEl.style.webkitTransform = 'translate3d(0px,' + swiperNested.translate * 0.3 + 'px,0px) scale(0.3)';
      //     swiperNested.wrapperEl.style.transform = 'translate3d(0px,' + swiperNested.translate * 0.3 + 'px,0px) scale(0.3)';
      //     swiperNested.wrapperEl.style.transitionDuration = '500ms';
      //     swiperNested.wrapperEl.style.webkitTransitionDuration = '500ms';
      //     // swiperNested.el.classList.remove('notTouched');
      //     // swiperNested.el.classList.add('isTouched');
      //     // document.getElementById('codicsStars').src = '/img/codics-icons/stars-small.svg'
      //     // console.log(document.getElementById('codicsStars').src);
      //     document.getElementById('sliderBgTitle').classList.remove('show');
      //     document.getElementsByClassName('home-slider-projects-title')[0].classList.remove('show');
      // });
      // swiperNested.on("touchMove", () => {
      //     console.log('touchMove');
      //
      //     swiperNested.wrapperEl.style.webkitTransform = 'translate3d(0,' + swiperNested.translate * 0.3 + 'px,0) scale(0.3)';
      //     swiperNested.wrapperEl.style.transform = 'translate3d(0,' + swiperNested.translate * 0.3 + 'px,0) scale(0.3)';
      //     swiperNested.wrapperEl.style.transitionDuration = '500ms';
      //     swiperNested.wrapperEl.style.webkitTransitionDuration = '500ms';
      //     // swiperNested.el.classList.remove('notTouched');
      //     // swiperNested.el.classList.add('isTouched');
      //     // document.getElementById('codicsStars').src = '/img/codics-icons/stars-small.svg';
      //     document.getElementById('sliderBgTitle').classList.remove('show');
      //     document.getElementsByClassName('home-slider-projects-title')[0].classList.remove('show');
      // });
      // swiperNested.on('touchEnd', () => {
      //
      //     console.log('touchEnd');
      //
      //     swiperNested.wrapperEl.style.webkitTransform = 'translate3d(0px,' + swiperNested.translate + 'px,0px) scale(1)';
      //     swiperNested.wrapperEl.style.transform = 'translate3d(0px,' + swiperNested.translate + 'px,0px) scale(1)';
      //     // swiper.wrapperEl.style.transitionDuration = '500ms';
      //     // swiper.wrapperEl.style.webkitTransitionDuration = '500ms';
      //     // document.getElementById('codicsStars').src = '/img/codics-icons/stars-big.svg'
      //     swiperNested.el.classList.add('notTouched');
      //     swiperNested.el.classList.remove('isTouched');
      //     this.homeIndex !== 0 && document.getElementById('sliderBgTitle').classList.add('show');
      //     document.getElementsByClassName('home-slider-projects-title')[0].classList.add('show');
      // });
    }
  };

  setMainSwiperStatus = (val) => {
    // console.log(`MainSwiper = ${val}`);

    this.swiperMain.allowSlidePrev = val;
    this.swiperMain.allowSlideNext = val;
    this.swiperMain.update();
    this.swiperNested.update();
  };

  setNestedSwiperStatus = (val) => {
    // console.log(`NestedSwiper = ${val}`);

    this.swiperNested.allowSlidePrev = val;
    this.swiperNested.allowSlideNext = val;
    this.swiperMain.update();
    this.swiperNested.update();
  };

  homeToTop = () => {
    const { swiperMain, swiperNested } = this;

    if (swiperMain !== null && swiperMain.realIndex !== 0) {
      this.setMainSwiperStatus(true);
      swiperMain.slideTo(0, isDesktop ? 1800 : 1000, false);

      if (swiperNested !== null) {
        setTimeout(
          () => {
            this.setNestedSwiperStatus(true);
            swiperNested.slideTo(0, 0, false);
            this.setNestedSwiperStatus(false);
          },
          isDesktop ? 1850 : 1050
        );
      }
    }
  };

  homeToProjects = () => {
    const { swiperMain } = this;

    if (swiperMain !== null && swiperMain.realIndex !== 1) {
      this.setMainSwiperStatus(true);
      swiperMain.slideTo(1, isDesktop ? 1800 : 1000, false);
      this.swiperNested.slideTo(0, isDesktop ? 1800 : 1000, false);
    }
  };
  breakDataToFourElems = (data) => {
    const newArr = [];
    let dataArr = [...data];
    while (dataArr.length > 4) {
      let tempArr = dataArr.splice(0, 4);
      newArr.push(tempArr);
    }
    newArr.push(dataArr);
    return newArr;
  };

  render() {
    const { showStars, isMobile, bgTitle } = this.state;
    const data = this.breakDataToFourElems(projects.data);
    return (
      <>
        <div className="home-slider-wrapper" id="homeSliderWrapper">
          {/* <HomeProjectInfo /> */}
          <div className={showStars ? "codics-stars show" : "codics-stars"}>
            <Stars />
          </div>
          <div className="home-slider-top">
            <Swiper {...this.swiperMainParams}>
              <div
                className="swiper-slide scrollSwiper-0"
                id="symbolsContainer"
              >
                <CodicsTop />
              </div>
              <div className="swiper-slide">
                {isMobile && <h2 className="projects">Projects</h2>}
                {isMobile && (
                  <h2
                    id="mobile-bg-title"
                    className="mobile-bg-title show"
                    dangerouslySetInnerHTML={{
                      __html: bgTitle,
                    }}
                  />
                )}
                <Swiper {...this.swiperNestedParams}>
                  {isMobile
                    ? projects.data.map((item, i) => {
                        return (
                          <div
                            className={`mySwiper-slide scrollSwiper-${i}`}
                            key={i}
                            // data-hash={item.url}
                          >
                            <div
                              className="myImg mobile-portrait"
                              style={{
                                backgroundImage: `url(${
                                  item.imgMobile ? item.imgMobile : item.img
                                })`,
                              }}
                            >
                              <Link
                                to={`/projects/${item.url}`}
                                onClick={() => {}}
                              >
                                <h2
                                  className="project-name"
                                  dangerouslySetInnerHTML={{
                                    __html: item.title,
                                  }}
                                />
                              </Link>
                            </div>
                          </div>
                        );
                      })
                    : data.map((item, i) => {
                        return (
                          <div
                            className={`mySwiper-slide scrollSwiper-${i}`}
                            key={i}
                            // data-hash={item[0].url}
                          >
                            <div className="row slides-container justify-content-left">
                              {item.map((el, idx) => {
                                return (
                                  <div
                                    className="col-md-6 px-5 h-50 pt-3 d-flex justify-content-center align-items-start"
                                    key={`${el.url}-${idx}`}
                                  >
                                    <div
                                      className="myImg"
                                      style={{
                                        backgroundImage: `url(${el.img})`,
                                      }}
                                    >
                                      <Link
                                        to={
                                          this.state.link
                                            ? `/projects/${el.url}`
                                            : ""
                                        }
                                        onClick={() => {}}
                                      >
                                        <h2
                                          className="project-name"
                                          dangerouslySetInnerHTML={{
                                            __html: el.title,
                                          }}
                                        />
                                        {/* <span className="project-slogan">
                                          {el.slogan}
                                        </span> */}
                                        {/* <span
                                          className="project-slogan-isTouched"
                                          dangerouslySetInnerHTML={{
                                            __html: el.title,
                                          }}
                                        /> */}
                                        <h2
                                          className="project-name-isTouched"
                                          dangerouslySetInnerHTML={{
                                            __html: el.title,
                                          }}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                </Swiper>
              </div>
            </Swiper>
          </div>
        </div>
      </>
    );
  }
}

export default MySlider;
