import React, { Component } from "react";
import Meta from "./Meta";
import ReactGA from "react-ga";
import jsonData from "../data/blogs/blogs-data.json";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

import { FiLink } from "react-icons/fi";
import ProjectFooter from "./ProjectFooter";
import NotFound from "../pages/NotFound";

// import {isDesktop} from "../../constants/Constants";

class BlogInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: [],
      count: 3,
      delay: 1000,
      isShow: false,
      data: null,
      pageUrl: "",
      currentSlug: "",
      notFound: false,
    };
  }

  componentDidMount() {
    document.getElementById("myHeader").classList.add("moveLeft");
    document.getElementById("blogs-flash-layer").classList.add("show");
    ReactGA.initialize("UA-106993262-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setCurrentData();
    this.setState({
      pageUrl: window.location.href,
    });
  }

  tick() {
    this.setState({ count: this.state.count - 1 });
  }

  goToNextBlog = (e, nextBlog) => {
    document.getElementById("blogs-flash-layer").classList.remove("show");
    clearInterval(this.timer);
    this.timer = setInterval(this.tick.bind(this), 1000);
    this.setState({
      isShow: true,
    });
    window.setTimeout(() => {
      clearInterval(this.timer);
      this.setState({
        isShow: false,
        count: 3,
      });
      this.props.history.push("/blog/" + nextBlog);
      this.setCurrentData();
      document.getElementById("blogs-flash-layer").classList.add("show");
      window.scrollTo({ top: 0, left: 0 });
    }, this.state.delay * 3);
  };

  setCurrentData = () => {
    const slug = this.props.location.pathname.split("/")[2];
    jsonData.data.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );

    const data = jsonData.data.filter((el) => el.slug === slug)[0];
    if (!data) {
      this.setState({
        notFound: true,
      });
    } else {
      this.setState({
        data,
        currentSlug: slug,
      });
    }
  };

  getNextBlog() {
    let nextIndex = 0;
    jsonData.data.forEach((blog, index) => {
      if (blog.slug === this.state.data.slug) {
        nextIndex = index + 1;
      }
    });

    if (nextIndex >= jsonData.data.length) {
      nextIndex = 0;
    }
    return jsonData.data[nextIndex].slug;
  }

  showCopyMsg() {
    document.getElementById("copy-msg").classList.add("active");
    window.setTimeout(() => {
      document.getElementById("copy-msg").classList.remove("active");
    }, 3000);
  }

  formatDate(date) {
    const d = new Date(date);
    const mounts = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const y = d.getFullYear();
    const m = mounts[d.getMonth()];
    let day = d.getDate();

    if (day < 9) {
      day = `0${day}`;
    }
    return `${m} ${day}, ${y}`;
  }

  render() {
    const { data, pageUrl, count, isShow, notFound } = this.state;

    return notFound ? (
      <NotFound />
    ) : (
      <div className="blogs-inner">
        <div className="blog-container">
          <div className="title-container">
            <h2>{data?.title}</h2>
          </div>

          <div className="blog-share">
            <h5>{data && this.formatDate(data.date)}</h5>
            <ul>
              <li>
                <FacebookShareButton url={pageUrl && pageUrl}>
                  <img src="/img/socials/facebook.svg" alt="fb-icon" />
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton url={pageUrl && pageUrl}>
                  <img src="/img/socials/twitter.svg" alt="fb-icon" />
                </TwitterShareButton>
              </li>
              <li
                className="link-copy-btn"
                onClick={() => {
                  this.showCopyMsg();
                  navigator.clipboard.writeText(pageUrl && pageUrl);
                }}
              >
                <span id="copy-msg" className="copy-msg">
                  Copied
                </span>
                <FiLink size={24} />
              </li>
              <li>
                <LinkedinShareButton url={pageUrl && pageUrl}>
                  <img src="/img/socials/linkedin.svg" alt="ln-icon" />
                </LinkedinShareButton>
              </li>
            </ul>
          </div>

          <div className="blog-banner-img">
            <img src={data?.img} alt="blog-img" />
          </div>

          <div className="blog-paragraph">
            <p
              dangerouslySetInnerHTML={{
                __html: data?.paragraph,
              }}
            />
          </div>
        </div>
        <ProjectFooter
          onClick={(e) => this.goToNextBlog(e, this.getNextBlog())}
          count={count}
          title={"SEE NEXT BLOG"}
          isCountShow={isShow}
        />
        <div id="blogs-flash-layer"></div>
      </div>
    );
  }
}

export default BlogInner;
