import React, {Component} from 'react'
import Stars from "../components/Stars";
import Helmet from "react-helmet";
import Meta from "../components/Meta";
import ReactGA from 'react-ga';

class About extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);

        if (this.props.location.pathname !== '/') {
            document.getElementById('myHeader').classList.add('show', 'moveLeft');
            [...document.querySelectorAll(".codics-scroll")].forEach(e => e.classList.add('visible'));
        } else {
            document.getElementById('myHeader').classList.remove('show', 'moveLeft', 'showFast');
        }
    }

    redirect = () => {
        this.props.history.push('/contact');
    };

    render() {
        // const title = 'About';
        return (
            <>
                <Meta title={"Software development - About us I Codics"}
                      desc={"Codics is a software production and digital storytelling company. We believe behind every code there is a story. Discover the stories we have coded."}
                      img={"/img/Codics.png"}/>
                <div className="myPages contact-center">
                    <div className="my-title">
                        About
                    </div>
                    <div className="codics-stars show">
                        <Stars/>
                    </div>
                    <div className="my-container mobileContainer">
                        <div className="my-row">
                            <div className="cl-7 mobileTextAlign">
                                <p className="bold-text">
                                    CODICS is a software production and digital storytelling company
                                </p>
                            </div>
                            <div className="cl-5">
                                <div className='mobileTextAlign mobileMarginBottom'>
                                    <span className='mobileBoldText'>
                                      We believe behind every code there is a story. Every new story that we encode is a challenge for our imagination, creativity and innovative thinking that inspires us to create a new marvel every single day.
                                    </span>
                                </div>
                                <div className='mobileTextAlign'>
                                    <span className='mobileBoldText'>
                                    We treat our clients as partners and together we script digital stories. We nurture relationships and build enduring partnerships through continuous excellence and finesse.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='project-footer' onClick={this.redirect}>
                        <p>
                            HEADQUARTERS
                        </p>
                    </div>
                </div>
            </>
        )
    }
}

export default About;