import React, { Component } from "react";
import { Link } from "react-router-dom";

class MyMenu extends Component {
  render() {
    const { isOpen } = this.props;
    return (
      <div className={isOpen ? "menu show" : "menu"}>
        <div className="menu-wrapper">
          <img
            className="codics-img"
            src="/img/codics-icons/big-stars.svg"
            alt=""
          />
          <div className="menu-navItems d-flex flex-column">
            <ul>
              <li>
                {/*<Link to={{  pathname: '/', state: { startProjects: true }}}>*/}
                {/*<div onClick={(e) => this.projectsClick(e)}>*/}
                {/*PROJECTS*/}
                {/*</div>*/}
                {/*</Link>*/}
                <Link
                  to={{ pathname: "/", state: { startProjects: true } }}
                  onClick={(e) => this.props.projectsClick(e)}
                >
                  <div className="linkContent">PROJECTS</div>
                </Link>
              </li>
              <li>
                <Link to={"/about"}>
                  <div
                    className="linkContent"
                    onClick={this.props.onClick.bind(this, "ABOUT")}
                  >
                    ABOUT
                  </div>
                </Link>
              </li>
              <li>
                <Link to={"/blog"}>
                  <div
                    className="linkContent"
                    onClick={this.props.onClick.bind(this, "BLOG")}
                  >
                    BLOG
                  </div>
                </Link>
              </li>
              <li>
                <Link to={"/careers"}>
                  <div
                    className="linkContent"
                    onClick={this.props.onClick.bind(this, "CAREERS")}
                  >
                    CAREERS
                  </div>
                </Link>
              </li>
              <li>
                <Link to={"/contact"}>
                  <div
                    className="linkContent"
                    onClick={this.props.onClick.bind(this, "HEADQUARTERS")}
                  >
                    HEADQUARTERS
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="socials">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/CodicsProducers/"
                  target="_blank"
                >
                  <img src="/img/codics-icons/fb@1x.svg" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.behance.net/codics_production?tracking_source=search-all%7Ccodics"
                  target="_blank"
                >
                  <img src="/img/codics-icons/behnce@1x.svg" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/codicsproduction/"
                  target="_blank"
                >
                  <img src="/img/codics-icons/insta@1x.svg" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/codics/"
                  target="_blank"
                >
                  <img src="/img/codics-icons/linkedin@1x.svg" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default MyMenu;
