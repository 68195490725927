import React, { Component } from "react";
import { Link } from "react-router-dom";

class NotFound extends Component {
  render() {
    return (
      <>
        <div className="not-found myPages">
          <Link to="/">
            <img src="/img/codics-icons/codics-logo.svg" alt="codics-logo" />
          </Link>
          <div className="notFound-body">
            <div>
              <div className="notFound-banner">
                <div className="notFound-404">
                  <div className="notFound-404-1">4</div>
                  <div className="notFound-404-2">0</div>
                  <div className="notFound-404-3">4</div>
                </div>
                <p className="notFound-notification">
                  <span>We couldn't find this page.</span>
                </p>
              </div>
            </div>

            <p className="notFound-links-title">
              <Link to="/">Go to Home</Link>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default NotFound;
