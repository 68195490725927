import React from "react";

const ThankYouModal = ({ text, onClose }) => {
  return (
    <div className="thank-you-modal">
      <div className="thank-you-modal-container">
        <p dangerouslySetInnerHTML={{ __html: text }} />
        <button className="mt-3 form-submit-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ThankYouModal;
