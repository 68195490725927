import React, {Component} from 'react'
// import {isDesktop} from "../../constants/Constants";
import Meta from "../Meta";
import ReactGA from "react-ga";
let isDesktop;
if (process.env.BROWSER) {
    isDesktop = window.matchMedia("(min-width: 812px)").matches;
}
class WCIT extends Component {
    componentDidMount() {
        ReactGA.initialize('UA-106993262-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div className="portfolio">
                <Meta title={"WCIT 2019 Yerevan - Explore website design story I Codics"}
                      desc={"When designing WCIT 2019 Yerevan website, we used 2 main interactions to create the mood of the Digital age. Read about design solutions here."}
                      img={"/img/projects-img/wcit.png"}/>
                <div className="portfolio-wcit-page">
                    <div className="container-1">

                        <img className="icon-img" src="/img/projects-img/wcit-logo.png" alt="wcit"/>

                        <h3 className="sub-title white first">The Power Of Decentralization</h3>

                        <img className="sub-img" src="/img/projects-img/wcit1.png" alt="wcit"/>

                        <div className="sub-text-wrapper">
                        <h3 className="sub-title white">The Challenge</h3>
                        <span className="sub-text white">
                            Our team was asked to create an informative and innovative website for one of the most important IT events in the world.
                            The main challenge was to make it impressive, eye-catchy and user-friendly. After research we decided to create the concept
                            around the idea of the evolution of the Digital Age, which was also one of the core themes of the event.
                        </span>
                        </div>

                        <video className="sub-movie" width={isDesktop ? '75%' : '100%'} controls>
                            <source src="/movie/WCIT Interaction.mp4" type="video/mp4"/>
                        </video>

                        <div className="sub-text-wrapper">
                        <h3 className="sub-title white">Interactions</h3>
                        <span className="sub-text white">
                            We used 2 main interactions to create the mood of the Digital age. Glitch animation which represents the beginning of
                            the Digital world and morphing shapes which are there to show the smooth and fast evolution of innovative technologies.
                       </span>
                        </div>

                        <img className="sub-img sub-img-last" src="/img/projects-img/wcit3.png" alt="wcit"/>

                    </div>

                    <div className="container-2">
                        <img className="sub-img" src="/img/projects-img/wcit35.png" alt="wcit"/>
                        <img className="sub-img" src="/img/projects-img/wcit4.png" alt="wcit"/>
                        <div className="sub-text-wrapper">
                             <span className="sub-text ">
                      Each of the title pages is an invitation to explore and learn more. The unique transition connects
                            all pages with each other making the navigation even more easy and user-friendly.
                   </span>
                        </div>
                        <img className="sub-img" src="/img/projects-img/wcit5.png" alt="wcit"/>
                    </div>

                    <div className="container-3">
                        <h3 className="sub-title white">Responsive Website</h3>

                        <img className="sub-img" src="/img/projects-img/wcit6.png" alt="wcit"/>

                        <div className="goTo white">
                            <a href='https://wcit2019.org/' target="_blank" rel="noopener noreferrer">
                                <span>Go To Website</span>
                                <img className="arrow" src="/img/projects-img/arrow.png" alt="arrow"/>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default WCIT;
